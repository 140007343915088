import axios from "axios";
import { Formik } from "formik";
import { useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import swal from "sweetalert"
export default function UploadReelVideo({show , onHide , courseId , reelId , refetchData}) {
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [createLesson, setCreateLesson] = useState({
        videsName : ""
    });
    const createLessonFileRefreance = useRef(null);
    const openCreateLessonFile = () => {
      createLessonFileRefreance.current.click();
    };
    const handleLessonFormUlpoader = async (value, { resetForm }) => {
        setIsUploading(true);
        setProgress(0);
    
        const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    
        let formData = new FormData();
        value.video && formData.append("video", value.video);
    
        const request = await axios
          .request({
            timeout: 0,
            method: "post",
            maxBodyLength: Infinity,
            maxContentLength: Infinity,
            url: `${process.env.REACT_APP_VERSEL_API}/api/user/video-reels/${reelId}/upload-video`,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${tokenData}`,
            },
            data: formData,
            onUploadProgress: (e) => {
              const uploadedRateData = Math.floor(
                Math.round(100 * e.loaded) / e.total
              );
              setProgress(uploadedRateData);
              console.log(uploadedRateData);
            },
          })
          .then((result) => {
            setIsUploading(false);
            setProgress(0);
            console.log(result)
            if (result.data.msg === "Video reel uploaded successfully") {
            onHide();
              swal("Video", "Video reel uploaded successfully ", "success");
              resetForm();
              refetchData()
            } else {
              swal("Error", result.data.msg, "error");
              setIsUploading(false);
              setProgress(0);
            }
          })
          .catch((error) => {
            console.log(error);
            swal("Oops", `${error}`, "error");
          });
      };
      
    return (

        <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header>Upload Reel Video</Modal.Header>
        
        <Modal.Body>
          <Formik
            initialValues={{
              video: createLesson.video,
            }}
            // validationSchema={lessonsSchemaValidation}
            onSubmit={handleLessonFormUlpoader}
          >
            {({
              values,
              errors,
              setValues,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <form onSubmit={handleSubmit}>
                <>
                  {/* Image Inputs Data */}
                  <div className="row">
                    <div
                      className="card-header"
                      style={{
                        border: "transparent",
                      }}
                    >
                      <h4 className="card-title">Uploade Video</h4>
                      <span className=" text-danger">Max Time ( 30 ) Seconds</span>
                    </div>
                    {/* Video  */}
                    <div className={`col-lg-12 col-sm-12 form-group mb-3`}>
                      <label
                        onClick={openCreateLessonFile}
                        className="text-label"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          padding: "2rem",
                          backgroundColor: "#1f88d9",
                          opacity: "0.5",
                          border: "3px dashed blue",
                          borderRadius: "10px",
                          color: "#111",
                          fontSize: "1rem",
                          textAlign: "center",
                          overflow: "hidden",
                        }}
                      >
                        {values.videsName
                          ? values.videsName
                          : "  Upload New Video  type of mp4 , mov , wmv , m4v"}
                      </label>
                      <div className="input-group">
                        <input
                          accept="video/*"
                          type="file"
                          className="form-control"
                          name="video"
                          hidden
                          ref={createLessonFileRefreance}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              videsName: e.target.files[0].name,
                              video: e.target.files[0],
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {isUploading && (
                    <>
                      <h5>uploading video now please waite...</h5>
                      <div className="progress-box">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5 className="mb-0 fs-14 font-w600">
                            Uploading video new.., please don't close window
                          </h5>
                          <span className="font-w600" id="progress-span">
                            {progress}%
                          </span>
                        </div>
                        <div className="progress ">
                          <div
                            className="progress-bar bg-primary"
                            id="progress-inner-width"
                            style={{
                              width: `${progress}%`,
                              height: "12px",
                              borderRadius: "4px",
                            }}
                            role="progressbar"
                          ></div>
                        </div>
                      </div>
                    </>
                  )}

                  {isUploading ? (
                    <>
                      <button className="btn me-2 btn-primary" disabled>
                        <Spinner animation="grow" variant="warning" />
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="btn me-2 btn-primary"
                      >
                        Submit
                      </button>
                    </>
                  )}
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn me-2 btn-danger"
                  >
                    Close
                  </button>
                </>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    )
}