import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getMonthlyMinutesViewsFunc } from "../../../../store/Slices/views/GetMonthlyMinutesViews";
import { Spinner } from "react-bootstrap";

export default function MonthlyViewsWithMinutesTable() {
    const [watchedList , setWatchedList] = useState([]);
    const [backupData,setBackupData] = useState([]);
    const dispatch = useDispatch();
    const {loading , data:monthlyViewsData , error} = useSelector(
        state => state.getMonthlyMinutesViews
    )
    const getViews = () => {
        dispatch(getMonthlyMinutesViewsFunc())
        .then((result) => {
            if(result?.payload?.data) {
                setBackupData(result?.payload?.data)
                setWatchedList(result?.payload?.data)
            }
        })
    }
    useEffect(() => getViews() , [])
    const filterFunc = (e) => {
      if(e.target.value === ""){
        setWatchedList(backupData)
        return;
      }
  
      const filterArray = backupData.filter((item) => {
        return (
          item.courseName.toLowerCase().includes(e.target.value.toLowerCase())
        );
      });
      setWatchedList(filterArray);
    };
    return (
        loading
        ?
        <>
            <div className="row d-flex justify-content-center gap-2">
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="warning" />
            </div>
        </>
        :
        (
            error 
            ?
            (
                <>{error}</>
            )
            :
            <>
                <div className="col-xl-12">
                <div className="card students-list">
                    <div className="card-header border-0 flex-wrap ">
                    <h4>Courses Monthly Views Minutes ({watchedList.length})</h4>
                    <div className="input-group search-area w-auto">
                        <span className="input-group-text">
                        <Link to={"#"}>
                            <svg
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                fill="var(--primary)"
                            ></path>
                            </svg>
                        </Link>
                        </span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        onChange={filterFunc}
                        />
                    </div>
                    </div>
                </div>
                </div>
                {watchedList.length == 0 ? (
                <>
                    <div className="row d-flex text-center gap-2">
                    <h3>There Is No Data</h3>
                    </div>
                </>
                ) : (
                <>
                    <div className="col-xl-12 ">
                    <div className="card p-1">
                        <div className="card-body ">
                        <div className="table-responsive">
                            <div
                            id="student_wrapper"
                            className="dataTables_wrapper no-footer"
                            >
                            <table
                                className="table display mb-4 dataTablesCard order-table card-table text-black application "
                                id="application-tbl1_next"
                            >
                                <thead>
                                <tr>
                                    <th>##</th>
                                    <th>Course</th>
                                    <th>Year</th>
                                    <th>Month</th>
                                    <th>Total Minutes</th>
                                    <th>Details</th>
                                </tr>
                                </thead>
                                <tbody>
                                {watchedList &&
                                    watchedList.map((item, index) => {
                                    return (
                                        <>
                                        <tr key={item.courseId}>
                                            <td>#</td>
                                            <td>
                                            <div className="d-flex align-items-center">
                                                <h4 className="mb-0 fs-16 font-w500">
                                                {
                                                item?.courseTitle?.length >= 30
                                                ?
                                                `${item?.courseTitle?.slice(0,25)}...`
                                                :
                                                item?.courseTitle
                                                }
                                                </h4>
                                            </div>
                                            </td>
                                            <td>{item?.year}</td>
                                            <td>{item?.month}</td>
                                            <td>{item?.totalMinutes?.toFixed()}</td>
                                            <td>
                                            <Link
                                                to={`/course-details-2/${item.courseId}`}
                                            >
                                                <i className="fa-solid fa-circle-info"></i>
                                            </Link>
                                            </td>
                                        </tr>
                                        </>
                                    );
                                    })}
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </>
                )}
            </>
        )
    )
}