import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import Spinner from "react-bootstrap/Spinner";

//images
import diplomaAvatar from "./../../../images/avatar/diplome-avatar.jpg";

import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { getALlDiplomesApi } from "../../../store/Slices/diplomes/getAllDiplomesSlicer";
import { deleteDiplomeByIdApi } from "../../../store/Slices/diplomes/deleteDiplomeByIdSlicer";

function DiplomesMain() {
  const [user, setUser] = useState({});
  const [diplomesData, setDiplomesData] = useState([]);
  const [backupData, setBackupData] = useState([]);

  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(
    (state) => state.getAllDiplomesSlicer
  );

  const getAlldiplomes = () => {
    dispatch(getALlDiplomesApi()).then((resulet) => {
      if (resulet.payload.message === "Data fetched successfully") {
        console.log(resulet.payload.data);
        setDiplomesData(resulet.payload.data);
        setBackupData(resulet.payload.data);
      }
    });
  };

  const getUserDate = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return userData;
  };

  const handleDeleteDiplome = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete this diploma",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteDiplomeByIdApi(id)).then((res) => {
          if (res.payload.message === "Data deleted successfully") {
            Swal.fire("Deleted!", "Lesson has been deleted", "success");
            getAlldiplomes();
          }
        });
      }
    });
  };
  const diplomaFilter = (e) => {
    if (e.target.value === "") {
      setDiplomesData(backupData);
      return;
    }

    const filterArray = backupData.filter((item) => {
      console.log(item);
      return item.name.en.toLowerCase().includes(e.target.value.toLowerCase());
    });
    console.log(filterArray);
    setDiplomesData(filterArray);
  };

  useEffect(() => {
    if (user) {
      setUser(getUserDate());
    }
    getAlldiplomes();
  }, []);

  useEffect(() => {
    if (!diplomesData) {
      getAlldiplomes();
    }
    return;
  }, [diplomesData]);

  return (
    <>
      {loading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          <div className="col-xl-12">
            <div className="card students-list">
              <div className="card-header border-0 flex-wrap ">
                <h4>All Diplomes ({backupData.length})</h4>
                <div className="input-group search-area w-auto">
                  <span className="input-group-text">
                    <Link to={"#"}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                          fill="var(--primary)"
                        ></path>
                      </svg>
                    </Link>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    onChange={diplomaFilter}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">All Diplomes</h3>

            <Link to={"./add-diplomes"} className="btn btn-primary btn-sm">
              Add Diplome
            </Link>
          </div>
          {diplomesData.length == 0 ? (
            <>
              <div className="row d-flex text-center gap-2">
                <h3>Data Not Found</h3>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                {diplomesData.map((item) => (
                  <CustomCourseCard
                    key={item._id}
                    title={item.name.en}
                    price={item.price}
                    image={item.image}
                    _id={item._id}
                    handleDeleteDiplome={handleDeleteDiplome}
                    subtitle={item.title.en}
                    teacher={item.teacher_id != null && item.teacher_id}
                    discountPrice = {item.discount_price}
                    finalPrice = {item.final_price / 100}
                    discountType = {item.discount_type}
                  />
                ))}
              </div>
              {/* <div className="pagination-down">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <h4 className="sm-mb-0 mb-3">
                    Showing <span>1-6 </span>from <span>100 </span>data
                  </h4>
                  <ul>
                    <li>
                      <Link to={"#"}>
                        <i className="fas fa-chevron-left"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={"#"} className="active">
                        1
                      </Link>
                    </li>
                    <li>
                      <Link to={"#"}>2</Link>
                    </li>
                    <li>
                      <Link to={"#"}>3</Link>
                    </li>
                    <li>
                      <Link to={"#"}>
                        <i className="fas fa-chevron-right"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </>
          )}
        </>
      )}
    </>
  );
}
export default DiplomesMain;

function CustomCourseCard({
  title,
  subtitle,
  price,
  _id,
  handleDeleteDiplome,
  image,
  teacher,
  discountPrice,
  discountType,
  finalPrice
}) {
  console.log(teacher);
  return (
    <>
      <div className="col-lg-6 col-md-6">
        <div className="card all-crs-wid">
          <div className="card-body">
            <div className="courses-bx">
              <div className="dlab-media">
                {image !== "" ? (
                  <>
                    <img
                      src={`${process.env.REACT_APP_VERSEL_API}/${image}`}
                      alt={title}
                    />
                  </>
                ) : (
                  <>
                    <img src={diplomaAvatar} alt="Avatat image" />
                  </>
                )}
                {/* {
                  parseInt(discountPrice) > 0
                  ?
                  <div className="discount-icon">
                    {
                      discountType === "fixed"
                      ?
                      `OFF ${discountPrice/100} EG`
                      :
                      `OFF ${discountPrice} %`

                    }
                  </div>
                  :
                  ""
                } */}
              </div>
              <div className="dlab-info">
                <div className="dlab-title d-flex justify-content-between">
                  <div>
                    <h4>
                      <Link to={`./diplome-detail/${_id}`}>{title}</Link>
                    </h4>
                    <h6>{subtitle}</h6>

                    {teacher &&
                      teacher.length > 0 &&
                      teacher.map((item, index) => (
                        <span className="m-0" key={index}>
                          {item && item.name}
                          <svg
                            className="ms-1"
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="2" cy="2.5" r="2" fill="#DBDBDB" />
                          </svg>{" "}
                        </span>
                      ))}
                  </div>
                  {/* <h4 className="text-primary">
                    {
                      discountPrice && parseInt(discountPrice) > 0
                      ?
                      <div className="position-relative flex items-center justify-content-around w-100">
                        <del className="mr-1 text-danger">${price / 100}</del>
                        <span className=" d-inline-block final-price">${finalPrice}</span>
                      </div>
                      :
                      <>${price / 100}</>
                    }
                  </h4> */}
                </div>
                <div className="d-flex justify-content-between content align-items-center">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDeleteDiplome(_id)}
                  >
                    Delete
                  </button>
                  <Link
                    to={`./diplome-detail/${_id}`}
                    className="btn btn-primary btn-sm"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
