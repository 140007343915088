import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import { LogoutFunction } from "../../../services/LogoutFunction";

export const updateReelDataFunc = createAsyncThunk(
  "update reel func",
  async ({data , id}) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/user/video-reels/${id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${tokenData}`,
          // "Content-Type": "application/json",
        },
        body: data,
      }
    )
      .then((respons) => respons.json())
      .then((resulet) => {
        console.log(resulet);
        return resulet;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });

    return request;
  }
);
function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const updateReelDataSlicer = createSlice({
  name: "reel/update",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(updateReelDataFunc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateReelDataFunc.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      }
      if (action.payload.message === "Reel updated successfully") {
        state.data = action.payload;
        state.error = null;
      } else {
        state.data = null;
        state.error = action.payload.message;
      }
    });

    builder.addCase(updateReelDataFunc.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.payload.message;
    });
  },
});

export default updateReelDataSlicer.reducer;
