import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Modal, Spinner } from "react-bootstrap";
import 'react-quill/dist/quill.snow.css';
import { addPlanFunc } from "../../../store/Slices/plans/AddPlanSlicer";
import { addReelFunc } from "../../../store/Slices/reels/AddReelSlicer";
const createJobSchema = Yup.object().shape({

  entitle: Yup.string()
    // .min(6, "English Title must consist of at least 6 characters ")
    // .max(300, "Job postion must consist of at most 300 characters ")
    .required("Please enter a Reel english Title"),
  artitle: Yup.string()
    // .min(6, "Arabic Title must consist of at least 6 characters ")
    // .max(300, "Job postion must consist of at most 300 characters ")
    .required("Please enter a Reel arabic Title"),
  endescription: Yup.string()
    .min(10, "English Description must consist of at least 10 characters ")
    // .max(300, "Job postion must consist of at most 300 characters ")
    .required("Please enter a Reel english Title"),
  ardescription: Yup.string()
    .min(10, "Arabic Description must consist of at least 10 characters ")
    // .max(300, "Job postion must consist of at most 300 characters ")
    .required("Please enter a Reel arabic Title"),

  // price: Yup.string()
    // .min(6, "Job location must consist of at least 3 characters ")
    // .max(300, "Job location must consist of at most 300 characters ")
    // .required("Please enter a Plan Price"),
  // duration: Yup.string()
    // .min(6, "Job location must consist of at least 3 characters ")
    // .max(300, "Job location must consist of at most 300 characters ")
    // .required("Please enter a Plan Duration"),
//   arlocation: Yup.string()
//     // .min(6, "Job postion must consist of at least 3 characters ")
//     // .max(300, "Job postion must consist of at most 300 characters ")
//     .required("Please enter a Job arabic loction"),
//   enCompanyName: Yup.string()
//     // .min(6, "Job postion must consist of at least 3 characters ")
//     // .max(300, "Job postion must consist of at most 300 characters ")
//     .required("Please enter English Company Name"),
//   arCompanyName: Yup.string()
//     // .min(6, "Job postion must consist of at least 3 characters ")
//     // .max(300, "Job postion must consist of at most 300 characters ")
//     .required("Please enter Arabic Company Name"),
});

const NewReelForm = ({showNewReelForm , onHide , courseId , refetchReel}) => {
  const { loading } = useSelector((state) => state.addReelSlicer);
  const dispatch = useDispatch();
  const [userdata, setUserData] = useState();
  const [visipilatyStatus , setvisipilatyStatus] = useState(false);
  const [freeStatus , setFreeStatus] = useState(false);
  const [discountType , setDiscountType] = useState();
  const [inputKey, setInputKey] = useState(Date.now());
  const history = useHistory();
  // File Refrances Basic Images
  const [planFormIntilization, setCourseFormInitialization] = useState({
    entitle : "",
    artitle : "",
    endescription : "",
    ardescription : "",
  });

  // Get All Data Function
  const handleSubmitData = (value, { resetForm }) => {
    const planData = {
        ar : {
            title : value.artitle,
            description : value.ardescription,
          },
        en : {
          title : value.entitle,
          description : value.endescription,
        },
        visible : visipilatyStatus,
        free : freeStatus,
    }

    const createFormData = (data) => {
      const formData = new FormData();
      formData.append("course_id", courseId);
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
            // Handle nested objects
            for (const nestedKey in data[key]) {
              if (data[key].hasOwnProperty(nestedKey)) {
                formData.append(`${nestedKey}[${key}]`, data[key][nestedKey]);
              }
            }
          } else {
            formData.append(key, data[key]);
          }
        }
      }
      return formData;
    };


    const reelData = createFormData(planData);
    const sendDataFunc = () => {
      dispatch(addReelFunc(reelData)).then((resulte) => {
        if (resulte.payload.message === "Video reel added successfully") {
          swal("Reel", "Reel Added Successfuly ", "success");
          refetchReel();
          resetForm();
          setvisipilatyStatus(false);
          setFreeStatus(false)
          onHide();
        }
        // if (resulte.payload.message === "Reel created successfully") {
        //   Swal.fire({
        //     title: resulte.payload.message,
        //     text: "Go to Plans Page !",
        //     icon: "success",
        //     showCancelButton: true,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     cancelButtonText: "No, stay and add new Plan",
        //     confirmButtonText: "Yes, Go to Plans Page",
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //         history.push(`/plans`);
        //     } else {
        //       resetForm();
        //       setvisipilatyStatus(false)
        //     }
        //   });
        //   setvisipilatyStatus(false);
        //   setFreeStatus(false)
        // } else {
        //   swal("error", resulte.payload.message, "warning");
        // }
      }
      );
    }
    sendDataFunc()
  };

  useEffect(() => {
    window.scroll(0, 0);
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data) {
      setUserData(data);
    } else {
      history.push("/login");
    }
  }, []);


  return (
    <Modal
    show = {showNewReelForm}
    onHide={onHide}
    >
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={{
                  entitle: planFormIntilization.entitle,
                  artitle: planFormIntilization.artitle,
                  endescription: planFormIntilization.endescription,
                  ardescription: planFormIntilization.ardescription,
                //   arpostion: planFormIntilization.arpostion,

                //   enlocation: planFormIntilization.enlocation,
                //   arlocation: planFormIntilization.arlocation,

                //   enCompanyName: planFormIntilization.enCompanyName,
                //   arCompanyName: planFormIntilization.arCompanyName,

                }}
                validationSchema={createJobSchema}
                onSubmit={handleSubmitData}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div>
                      <h1>Reel Infromation</h1>
                      <section>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.entitle
                                ? errors.entitle
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English Title
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter English Title.."
                                name="entitle"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.entitle}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.entitle && errors.entitle}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.artitle
                                ? errors.artitle
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic Title
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Arabic Title.."
                                name="artitle"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.artitle}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.artitle && errors.artitle}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="visiblaty-div row mb-2">
                            <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                              <input id="visiblaty"  type="checkbox" className="mr-1 cursor-pointer scale2"
                                onChange={(e) => {
                                  setvisipilatyStatus(!visipilatyStatus);
                                }}
                                checked = {visipilatyStatus}
                              />
                              <label htmlFor="visiblaty" className="is-free-label cursor-pointer pl-2 mb-0">Visability</label>
                            </div>
                        </div>
                        <div className="visiblaty-div row mb-2">
                            <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                              <input id="freeStatus"  type="checkbox" className="mr-1 cursor-pointer scale2"
                                onChange={(e) => {
                                    setFreeStatus(!freeStatus);
                                }}
                                checked = {freeStatus}
                              />
                              <label htmlFor="freeStatus" className="is-free-label cursor-pointer pl-2 mb-0">Avilable For Free</label>
                            </div>
                        </div>
                        <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                  values.endescription
                                    ? errors.endescription
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Enter Enlgish Description*
                                </label>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                  </span>
                                  <textarea
                                    className="form-control"
                                    placeholder="Enter English Description .."
                                    name="endescription"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.endescription}
                                  ></textarea>
                                  <div
                                    id="val-price-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.endescription && errors.endescription}
                                  </div>
                                </div>
                              </div>
                        </div>
                        <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                  values.ardescription
                                    ? errors.ardescription
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Enter Arabic Description*
                                </label>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                  </span>
                                  <textarea
                                    className="form-control"
                                    placeholder="Enter Arabic Description .."
                                    name="ardescription"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.ardescription}
                                  ></textarea>
                                  <div
                                    id="val-price-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.ardescription && errors.ardescription}
                                  </div>
                                </div>
                              </div>
                        </div>
                        {/* <div className="row">
                            <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 
                        
                            `}
                            >
                            <label className="text-label">
                                Choose discount type*
                            </label>
                            <div className="input-group">
                                <select
                                className="form-control"
                                name="discount-type"
                                onChange={(e) => setDiscountType(e.target.value)}
                                // onBlur={handleBlur}
                                defaultValue={discountType}
                                >
                                <option value={"Choose Discount Type.."}>Choose Discount Type..</option>
                                <option value={"fixed"}>Fixed</option>
                                <option value={"percentage"}>Percentage</option>
                                </select>
                                <div
                                id="val-price-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                                >
                                {errors.discountType && errors.discountType}
                                </div>
                            </div>
                            </div>
                        </div> */}
                      </section>
                    </div>
                    <div className="row">
                      <div className="col">
                        {loading ? (
                          <>
                            <Button variant="primary" disabled>
                              <Spinner animation="grow" variant="info" />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button variant="primary" type="submit">
                              Create
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewReelForm;
