import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const deleteReelFunc = createAsyncThunk("reels/delete", async (id) => {
  const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
  const request = await fetch(
    `${process.env.REACT_APP_VERSEL_API}/api/user/video-reels/${id}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${tokenData}`,
      },
    }
  )
    .then((respons) => respons.json())
    .then((resulet) => {
      return resulet;
    })
    .catch((e) => {
      return e;
    });

  return request;
});


const deleteReelSlicer = createSlice({
  name: "reels/delete",
  initialState: {
    responst: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteReelFunc.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.responst = action.payload.message;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(deleteReelFunc.rejected, (state, action) => {
      state.error = action.payload.message;
    });
  },
});

export default deleteReelSlicer.reducer;
