import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import AllSubscriptionsTable from "./components/DisplayAllCourseSubscriptionsTableData";

export default function AllSubscriptionsPage() {
  return (
    <>
      <Fragment>
        <PageTitle
          activeMenu="Subscriptions"
          motherMenu="Dashboard"
        />
        <AllSubscriptionsTable />
      </Fragment>
    </>
  );
}
