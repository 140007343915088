// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import swal from "sweetalert";

// export const getAllSubscriptionsFunc = createAsyncThunk(
//   "subscriptions/getAll",
//   async (pageNumber) => {
//     const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
//     const request = await fetch(
//       `${process.env.REACT_APP_VERSEL_API}/api/user/subscripers/?page=${pageNumber}`,
//       {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${tokenData}`,
//         },
//       }
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         return result;
//       })
//       .catch((error) => {
//         formatError(error);
//         return error;
//       });

//     return request;
//   }
// );

// function formatError(errorResponse) {
//   swal("Oops", `${errorResponse}`, "error");
// }

// const getAllSubscriptionsSlicer = createSlice({
//   name: "getAllSubscriptionsSlicer",
//   initialState: {
//     loading: false,
//     data: null,
//     error: null,
//   },
//   extraReducers: (builder) => {
//     builder.addCase(getAllSubscriptionsFunc.pending, (state) => {
//       state.loading = true;
//     });
//     builder.addCase(getAllSubscriptionsFunc.fulfilled, (state, action) => {
//       state.loading = false;
//       state.data = action.payload;
//     //   if (action.payload.message === "fetched successfully") {
//     //   } else {
//     //     state.error = action.payload.message;
//     //     formatError(state.error);
//     //   }
//     if (action.payload.data) {
//         state.data = action.payload;
//       } else {
//         state.error = action.payload.message;
//         formatError(state.error);
//       }
//     });
//     builder.addCase(getAllSubscriptionsFunc.rejected, (state, action) => {
//       state.loading = false;
//       state.error = action.payload;
//       formatError(state.error);
//     });
//   },
// });

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const getAllSubscriptionsFunc = createAsyncThunk(
  "plans/get/func",
//   async (pageNumber) => {
//     const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
//     const request = await fetch(
//       `${process.env.REACT_APP_VERSEL_API}/api/user/subscripers/?page=${pageNumber}`,
//       {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${tokenData}`,
//         },
//       }
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         return result;
//       })
//       .catch((error) => {
//         formatError(error);
//         return error;
//       });

//     return request;
//   }
  async (pageNumber) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/user/subscripers/?page=${pageNumber}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        formatError(error);
        return error;
      });

    return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const getAllSubscriptionsSlicer = createSlice({
  name: "plans/get/Slicer",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSubscriptionsFunc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllSubscriptionsFunc.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data) {
        state.data = action.payload;
      } else {
        state.error = action.payload.message;
        formatError(state.error);
      }
    });
    builder.addCase(getAllSubscriptionsFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      formatError(state.error);
    });
  },
});

export default getAllSubscriptionsSlicer.reducer;
