// import { useContext, useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import { useDispatch, useSelector } from "react-redux";
// import { Spinner } from "react-bootstrap";
// import ContextApi from "../../../store/ContextApi";
// import PageTitle from "../../layouts/PageTitle";
// import { postBasicSettingsFunc } from "../../../store/custom-actions/basic-settings/PostBasicSettingsSlicer";
// import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { postSettingsFunc } from "../../../store/Slices/settings/PostSettingsSlicer";
import { Button, Spinner } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import Swal from "sweetalert2";
import { getBasicSettingsFunc } from "../../../store/Slices/settings/GetBasicSettingsSlicer";

function BasicSettingsPage() {
    const {loading:getBasicSettingsLoading , data:settingsData} = useSelector(
        state => state.getBasicSettingsSlicer
    )
    const {loading:postSettingsLoading} = useSelector(
        state => state.postSettingsSlicer
    );
    const dispatch = useDispatch();
    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    const [showLogo , setShowLogo] = useState(null);
    const [image , setImage] = useState(null)
    const getBasicSettings = () => {
        dispatch(getBasicSettingsFunc())
        .then((result) => {
            console.log(result.payload)
            if(result?.payload?.length >= 1) {
                for(let i = 0 ; i <= result?.payload?.length ; i++) {
                    console.log(result?.payload[i])
                    switch(result?.payload[i]?.option) {
                        case "path" :
                            setValue("path" , result?.payload[i]?.value);
                            break;
                        default : 
                        console.log("no data")
                    }
                }
            }
        })
    }
    useEffect(() => {
        getBasicSettings()
    },[])
    const handleSubmitSettings = async (data) => {
        if(data) {
            const dataForm = new FormData();
            dataForm.append(`settings[0][option]` , `path`);
            dataForm.append(`settings[0][value]` , data.path);
            dataForm.append(`settings[1][option]` , `image`);
            dataForm.append(`settings[1][value]` , ``);
            dataForm.append("image" , image);
            dispatch(postSettingsFunc(dataForm))
            .then((result) => {
                if(result?.payload?.message === "Settings saved successfully") {
                    Swal.fire({
                        title: "Success",
                        text: "Settings saved successfully",
                        icon: "success",
                    })
                }
            })
        }
    }
    return (
        <>
            <PageTitle motherMenu={"Settings"} activeMenu={"Basic Settings"} customClasses={"mb-0"}/>
            {
                getBasicSettingsLoading
                ?
                <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                </div>
                :
                <div className="card p-3">
                    <div className="relative settings-form-content">
                        <div className="new-settings-form-div relative w-full ">
                            <form 
                                className="new-settings-form"
                                id="new-settings-form"
                                onSubmit={handleSubmit((data) => {
                                    handleSubmitSettings(data)
                                })}
                            >
                                {
                                    <>
                                        <div className="row">
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-link mx-1"></i>
                                                    Slider Path
                                                </label>
                                                {errors.path?.message && (
                                                <p className="text-red-600">
                                                    {errors.path?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("path")}
                                                type="text"
                                                className="form-control"
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-image mx-1"></i>
                                                    Slider Backgorund
                                                </label>
                                                <input
                                                {...register("site_global_email")}
                                                type="file"
                                                className="form-control"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    file && setImage(file)
                                                }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {postSettingsLoading ? (
                          <>
                            <Button variant="primary" disabled>
                              <Spinner animation="grow" variant="info" />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button variant="primary" type="submit" form="new-settings-form">
                              Submit
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                </div>
            }
        </>
    )
}


export default BasicSettingsPage;