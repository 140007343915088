import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../../../services/LogoutFunction";
import axios from "axios";

export const addSubscription = createAsyncThunk(
  "user/addSubscription",
  async (subscriptionData) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    const config = {
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
    }
    const response = axios.post(`${process.env.REACT_APP_VERSEL_API}/api/user/subscripers` , subscriptionData , config);
    return response
    // const request = await fetch(
    //   `${process.env.REACT_APP_VERSEL_API}/api/user/subscripers`,
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${tokenData}`,
    //     },
    //     body: JSON.stringify(subscriptionData),
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     return result;
    //   })
    //   .catch((error) => {
    //     formatError(error);
    //     return error;
    //   });

    // return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}
const addSubscriptionSlicer = createSlice({
  name: "addSubscription",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(addSubscription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addSubscription.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload
    //   if (action.payload.message === "jwt expired") {
    //     state.error = action.payload.message;
    // }
    })
    builder.addCase(addSubscription.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default addSubscriptionSlicer.reducer;
