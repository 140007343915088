import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Spinner from "react-bootstrap/Spinner";

//images
import palette from "./../../../images/svg/color-palette.svg";
import education from "./../../../images/svg/education-website.svg";
import brain from "./../../../images/svg/brain.svg";
import microscope from "./../../../images/svg/microscope.svg";
import course1 from "./../../../images/courses/course1.jpg";
import course2 from "./../../../images/courses/course2.jpg";
import course3 from "./../../../images/courses/course3.jpg";
import course4 from "./../../../images/courses/course4.jpg";
import course5 from "./../../../images/courses/course5.jpg";
import course6 from "./../../../images/courses/course6.jpg";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { deleteCourseApi } from "../../../store/DeleteCourseSlicer";
import { deletePlanFunc } from "../../../store/Slices/plans/DeletePlanSlicer";
import { getPlansfunc } from "../../../store/Slices/plans/GetPlansSlicer";
import UpdatePlanForm from "./UpdatePlanForm";
import planImg from "../../../images/3d-rendering-graphic-design_23-2149642704.webp"
function PlansPage() {
  const [user, setUser] = useState({});
  // const [coursesData, setPlansList] = useState([]);
  const [backupData, setBackupData] = useState([]);
    const [palnsList , setPlansList] = useState([])
  const dispatch = useDispatch();
  const { loading:getPlansLoading, data:plansData, error } = useSelector((state) => state.getPlansSlicer);

  const getPlans = () => {
    dispatch(getPlansfunc()).then((resulet) => {
      console.log(resulet)
      if (resulet.payload.data) {
        setPlansList(resulet.payload.data);
        setBackupData(resulet.payload.data);
      } else {
        setPlansList([]);
      }
    });
  };

  const getUserDate = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return userData;
  };

  const handleDeletePlan = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete this Plan",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePlanFunc(id)).then((res) => {
          if (res.payload.date != []) {
            Swal.fire("Deleted!", "Plan has been deleted", "success");
            getPlans();
          }
        });
      }
    });
  };
  const plansFilter = (e) => {
    if (e.target.value === "") {
      setPlansList(backupData);
      return;
    }
    const filterArray = backupData.filter((item) => {
      console.log(item);
      return (
        item.name.en.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    console.log(filterArray);
    setPlansList(filterArray);
  };

  useEffect(() => {
    getPlans(1);
  }, []);

  // useEffect(() => {
  //   if (!coursesData) {
  //     getPlans();
  //   }
  //   return;
  // }, [coursesData]);

  return (
    <>

      {getPlansLoading? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          <div className="col-xl-12">
            <div className="card students-list">
              <div className="card-header border-0 flex-wrap ">
                <h4>All Plans ({backupData.length})</h4>
                <div className="input-group search-area w-auto">
                  <span className="input-group-text">
                    <Link to={"#"}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                          fill="var(--primary)"
                        ></path>
                      </svg>
                    </Link>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    onChange={plansFilter}
                  />
                <Link 
                    to="/new-plan"
                    style={{marginLeft : "5px"}}
                    className = "btn bg-primary text-white d-flex justify-content-center align-items-center">
                    Create
                </Link>
                </div>
              </div>
            </div>
          </div>
          {palnsList.length == 0 ? (
            <>
              <div className="row d-flex text-center gap-2">
                <h3>Data Not Found</h3>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                {palnsList.map((item) => (
                  <PlanCard
                    plan = {item}
                    key={item._id}
                    title={item.name.en}
                    price={item.price}
                    _id={item._id}
                    handleDeletePlan={handleDeletePlan}
                    discountPrice = {item.discount_price}
                    finalPrice = {item.final_price}
                    discountType = {item.discount_type}
                    refetchPlans = {() => getPlans()}
                  />
                ))}
                <>
                </>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
export default PlansPage;

function PlanCard({
  plan,
  title,
  subtitle,
  price,
  _id,
  handleDeletePlan,
  discountPrice,
  finalPrice,
  discountType, 
  refetchPlans
}) {
  const [showUpdate , setShowUpdate] = useState();
  const [planData , setPlanData] = useState();
  const handleShowUpdate = () => {
    setShowUpdate(true);
    setPlanData(plan)
  }
  return (
    <>
      <div className="col-xl-4 col-md-6">
        <div className="card all-crs-wid">
          <div className="card-body">
            <div className="courses-bx">
              <div className="dlab-media">
                <img
                  src={`${planImg}`}
                  alt="plan-img"
                />
                {
                  parseInt(discountPrice) > 0
                  ?
                  <div className="discount-icon">
                    {
                      discountType === "fixed"
                      ?
                      `OFF ${discountPrice} EG`
                      :
                      `OFF ${discountPrice} %`

                    }
                  </div>
                  :
                  ""
                }
              </div>
              <div className="dlab-info">
                <div className="dlab-title d-flex justify-content-between">
                  <div>
                    <h4>
                      <Link to={`./course-details-2/${_id}`}>{title}</Link>
                    </h4>
                    {
                      parseInt(discountPrice) > 0
                      ?
                      <h4 className="text-primary w-100 position-relative">
                      {
                        <>
                          {/* <span>$</span> */}
                          {
                            discountPrice && parseInt(discountPrice) > 0
                            ?
                            <div className="position-relative flex items-center justify-content-around w-100">
                              <del className="mr-1 text-danger">${price}</del>
                              <span className=" d-inline-block final-price">${finalPrice}</span>
                            </div>
                            :
                            <>${price}</>
                          }
                        </>
                      }
                      </h4>
                      :
                      ""
                    }
                    <p className="m-0">
                      {subtitle && subtitle.name}
                      <svg
                        className="ms-1"
                        width="4"
                        height="5"
                        viewBox="0 0 4 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="2" cy="2.5" r="2" fill="#DBDBDB" />
                      </svg>
                    </p>
                  </div>
                  {
                    parseInt(discountPrice) === 0
                    ?
                    <h4 className="text-primary">
                      {
                        // freeStatus
                        // ?
                        // "Free Course"
                        // :
                        <>
                          <span>$</span>
                          {
                            price
                          }
                        </>
                      }
                    </h4>
                    :
                    ""
                  }
                </div>
                <div className="d-flex justify-content-between content align-items-center">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDeletePlan(_id)}
                  >
                    Delete
                  </button>
                  {/* <Link
                    to={`./course-details-2/${_id}`}
                    className="btn btn-primary btn-sm"
                  >
                    View Details
                  </Link> */}
                  <Link
                    to={`#`}
                    className="btn btn-primary btn-sm"
                    onClick = {handleShowUpdate}
                  >
                    Update
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdatePlanForm
      show={showUpdate}
      onHide={() => setShowUpdate(false)}
      planData={plan}
      id = {_id}
      refetchPlans = {() => refetchPlans()}
      />
    </>
  );
}