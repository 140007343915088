import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Modal, Spinner } from "react-bootstrap";
import 'react-quill/dist/quill.snow.css';
import { addJobFunc } from "../../../store/Slices/jobs/AddJobSlicer";
import { addPlanFunc } from "../../../store/Slices/plans/AddPlanSlicer";
import { updatePlanFunc } from "../../../store/Slices/plans/UpdatePlanSlicer";
const createJobSchema = Yup.object().shape({

  enname: Yup.string()
    .min(6, "English Name must consist of at least 6 characters ")
    // .max(300, "Job postion must consist of at most 300 characters ")
    .required("Please enter a Plan english postion"),
  arname: Yup.string()
    .min(6, "Arabic Name must consist of at least 6 characters ")
    // .max(300, "Job postion must consist of at most 300 characters ")
    .required("Please enter a Plan arabic postion"),

  price: Yup.string()
    // .min(6, "Job location must consist of at least 3 characters ")
    // .max(300, "Job location must consist of at most 300 characters ")
    .required("Please enter a Plan Price"),
//   arlocation: Yup.string()
//     // .min(6, "Job postion must consist of at least 3 characters ")
//     // .max(300, "Job postion must consist of at most 300 characters ")
//     .required("Please enter a Job arabic loction"),
//   enCompanyName: Yup.string()
//     // .min(6, "Job postion must consist of at least 3 characters ")
//     // .max(300, "Job postion must consist of at most 300 characters ")
//     .required("Please enter English Company Name"),
//   arCompanyName: Yup.string()
//     // .min(6, "Job postion must consist of at least 3 characters ")
//     // .max(300, "Job postion must consist of at most 300 characters ")
//     .required("Please enter Arabic Company Name"),
});

const UpdatePlanForm = ({show , onHide , planData , id , refetchPlans}) => {

  const { loading } = useSelector((state) => state.addJobSlicer);
  const { loading:updatePlanLoading } = useSelector((state) => state.updatePlanSlicer);

  const dispatch = useDispatch();
  const [userdata, setUserData] = useState();
  const [visipilatyStatus , setvisipilatyStatus] = useState(false);
  const [workType , setWorkType] = useState("remotly");
  const [paidStatus , setPaidStatus] = useState(true);
  // const [paidStatus , setPaidStatus] = useState("paid");
  const [enDescription , setEnDescription] = useState("");
  const [arDescription , setArDescription] = useState("");
  const [errEnDescription , setErrEnDescription] = useState(true);
  const [discountType , setDiscountType] = useState();
  const [image , setImage] = useState(null)
  const [imageViewr , setImageViewr] = useState(null);
  const history = useHistory();
  // File Refrances Basic Images
  const [planFormIntilization, setPlanFormInitialization] = useState({
    enname : "",
    arname : "",
    price : "",
    discount_price : "",
    discountType : "",
    duration : "",
  });
  useEffect(() => {
    if(planData) {
        setPlanFormInitialization({
            enname : planData?.name?.en,
            arname : planData?.name?.ar,
            price : planData?.price,
            discount_price : planData?.discount_price,
            duration : planData?.duration,
        })
        setvisipilatyStatus(planData?.visible)
        setDiscountType(planData?.discount_type)
    }
  },[planData])
  // Get All Data Function
  const handleSubmitData = (value, { resetForm }) => {
    const planData = {
        ar : {
            name : value.arname,
          },
        en : {
            name : value.enname,
        },
        duration : value.duration,
        price : value.price,
        // discount_price : value.discount_price,
        // discount_type : discountType,
        ...(value.discount_price ? {discount_price : value.discount_price} : {discount_price : 0}),
        ...(discountType ? {discount_type : discountType} : {discount_type : "fixed"}),
        visible : visipilatyStatus,
    }
    const createFormData = (data) => {
      const formData = new FormData();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
            // Handle nested objects
            for (const nestedKey in data[key]) {
              if (data[key].hasOwnProperty(nestedKey)) {
                formData.append(`${nestedKey}[${key}]`, data[key][nestedKey]);
              }
            }
          } else {
            formData.append(key, data[key]);
          }
        }
      }
    //   formData.append("_method" , "PUT")
      return formData;
    };
    const jobData = createFormData(planData);
    const sendDataFunc = () => {
      dispatch(updatePlanFunc({data: jobData , id})).then((resulte) => {
        console.log(resulte)
        if (resulte.payload.message === "Plan updated successfully") {
            onHide();
            refetchPlans();
            resetForm();
            setvisipilatyStatus(false);
            swal("Done", resulte.payload.message, "success");
        } else {
          swal("Done", "Plan updated successfully", "warning");
        }
      });
    }
    if(value.discount_price && (!discountType || discountType === "Choose Discount Type..")) {
        Swal.fire({
          title: "Discount Type Required!",
          text: "When Choose Discount You Should Choose Type Of it!",
          icon: "warning",
          // showCancelButton: true,
          // confirmButtonColor: "#3085d6",
          // cancelButtonColor: "#d33",
          // cancelButtonText: "No, stay and add new Plan",
          // confirmButtonText: "Yes, Go to Plans Page",
        })
      }else{
        sendDataFunc();
      }
  };

  useEffect(() => {
    window.scroll(0, 0);
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data) {
      setUserData(data);
    } else {
      history.push("/login");
    }
  }, []);


  return (
    <Modal
    show={show}
    onHide={onHide}
    backdrop="static"
    keyboard={false}
    size="lg"
    >
    <Modal.Body>
        <Fragment>
        <div className="row">
            <div className="col-xl-12 col-xxl-12">
            <div className="card">
                <div className="card-body">
                <Formik
                    initialValues={{
                    enname: planFormIntilization.enname,
                    arname: planFormIntilization.arname,
                    
                    price: planFormIntilization.price,
                    discount_price: planFormIntilization.discount_price,
                    discountType: planFormIntilization.discountType,
                    duration: planFormIntilization.duration,
                    //   arpostion: planFormIntilization.arpostion,

                    //   enlocation: planFormIntilization.enlocation,
                    //   arlocation: planFormIntilization.arlocation,

                    //   enCompanyName: planFormIntilization.enCompanyName,
                    //   arCompanyName: planFormIntilization.arCompanyName,

                    }}
                    validationSchema={createJobSchema}
                    onSubmit={handleSubmitData}
                >
                    {({
                    values,
                    errors,
                    setValues,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    }) => (
                    <form className="row" onSubmit={handleSubmit}>
                        <div>
                        <h1>Plan Infromation</h1>
                        <section>
                            <div className="row">
                            <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                values.enname
                                    ? errors.enname
                                    ? "is-invalid"
                                    : "is-valid"
                                    : ""
                                }`}
                            >
                                <label className="text-label">
                                Enter English Name
                                </label>
                                <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter English Name.."
                                    name="enname"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.enname}
                                />
                                <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                >
                                    {errors.enname && errors.enname}
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                values.arname
                                    ? errors.arname
                                    ? "is-invalid"
                                    : "is-valid"
                                    : ""
                                }`}
                            >
                                <label className="text-label">
                                Enter Arabic Name
                                </label>
                                <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Arabic Name.."
                                    name="arname"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.arname}
                                />
                                <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                >
                                    {errors.arname && errors.arname}
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                values.price
                                    ? errors.price
                                    ? "is-invalid"
                                    : "is-valid"
                                    : ""
                                }`}
                            >
                                <label className="text-label">
                                Enter Price
                                </label>
                                <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                </span>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Price.."
                                    name="price"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.price}
                                />
                                <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                >
                                    {errors.price && errors.price}
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                values.duration
                                    ? errors.duration
                                    ? "is-invalid"
                                    : "is-valid"
                                    : ""
                                }`}
                            >
                                <label className="text-label">
                                Enter Duration (With Month *)
                                </label>
                                <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Duration.."
                                    name="duration"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.duration}
                                />
                                <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                >
                                    {errors.duration && errors.duration}
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="visiblaty-div row mb-2">
                                <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                                <input id="visiblaty"  type="checkbox" className="mr-1 cursor-pointer scale2"
                                    onChange={(e) => {
                                    setvisipilatyStatus(!visipilatyStatus);
                                    }}
                                    checked = {visipilatyStatus}
                                />
                                <label htmlFor="visiblaty" className="is-free-label cursor-pointer pl-2 mb-0">Visability</label>
                                </div>
                            </div>
                            <div className="row">
                                <div
                                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                    values.discount_price
                                        ? errors.discount_price
                                        ? "is-invalid"
                                        : "is-valid"
                                        : ""
                                    }`}
                                >
                                    <label className="text-label">
                                    Enter Discount*
                                    </label>
                                    <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="fa-solid fa-file-signature"></i>
                                    </span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Enter Plan discount .."
                                        name="discount_price"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.discount_price}
                                        // min={10}
                                    />
                                    <div
                                        id="val-price-error"
                                        className="invalid-feedback animated fadeInUp"
                                        style={{ display: "block" }}
                                    >
                                        {errors.discount_price && errors.discount_price}
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 
                            
                                `}
                                >
                                <label className="text-label">
                                    Choose discount type*
                                </label>
                                <div className="input-group">
                                    <select
                                    className="form-control"
                                    name="discount-type"
                                    onChange={(e) => setDiscountType(e.target.value)}
                                    // onBlur={handleBlur}
                                    defaultValue={discountType}
                                    >
                                    <option value={"Choose Discount Type.."}>Choose Discount Type..</option>
                                    <option value={"fixed"}>Fixed</option>
                                    <option value={"percentage"}>Percentage</option>
                                    </select>
                                    <div
                                    id="val-price-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                    >
                                    {errors.discountType && errors.discountType}
                                    </div>
                                </div>
                                </div>
                            </div>
                        </section>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Button
                                    variant="secondary"
                                    onClick={onHide}
                                    
                                >
                                    Close
                                </Button>
                                {updatePlanLoading ? (
                                    <>
                                    <Button variant="primary" disabled className="mx-2">
                                        Updateting ...
                                    </Button>
                                    </>
                                ) : (
                                    <>
                                    <Button variant="primary" type="submit" className="mx-2">
                                        Update
                                    </Button>
                                    </>
                                )}

                            </div>
                        </div>
                    </form>
                    )}
                </Formik>
                </div>
            </div>
            </div>
        </div>
        </Fragment>
    </Modal.Body>
    </Modal>
  );
};

export default UpdatePlanForm;
