import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Alert, Col, Dropdown } from "react-bootstrap";

//import StudentApexLineChart from './Instructor/StudentApexLineChart';
//import UserApexLineChart from './Instructor/UserApexLineChart';
//import StudentsActivityApex from './Instructor/StudentsActivityApex';

import pic3 from "./../../../images/courses/pic3.jpg";
import pic2 from "./../../../images/courses/pic2.jpg";
import pic4 from "./../../../images/courses/pic4.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../../../store/GetUsersSlicer";
import { Spinner } from "react-bootstrap";
import { deleteUser } from "../../../store/DeleteStudentSlicer";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import { updateUserApi } from "../../../store/UpdateUserDataSlicer";

const StudentApexLineChart = loadable(() =>
  pMinDelay(import("./Instructor/StudentApexLineChart"), 1000)
);

const studentTableBlog = [
  {
    image: pic3,
    title: "Karen Hope",
    status: "On Progress",
    changeClass: "badge-warning",
  },
  {
    image: pic2,
    title: "Jordan Nico",
    status: "No Progress",
    changeClass: "badge-primary",
  },
  {
    image: pic4,
    title: "Johnny Ahmad",
    status: "Completed",
    changeClass: "badge-success",
  },
];
function CustomDropDownBlog(props) {
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          as="a"
          className="btn-link i-false btn sharp tp-btn-light btn-dark"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
              fill="#A098AE"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-end"
          align="right"
        >
          <Dropdown.Item
            onClick={() => props.handleClick("DELETE", props.dataitem)}
          >
            Delete
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.handleClick("EDIT", props.dataitem)}
          >
            Edit
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

const InstructorStudents = () => {
  const { loading: userUpdateLoading, error: userUpdateError } = useSelector(
    (state) => state.updateUser
  );
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  // form controle
  const [userId, setUserId] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");

  //form errors
  const [fullNameErrorMessage, setFullNameErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  // const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setUserId("");
    setFullName("");
    setEmail("");
    // setPassword("");
    setPhone("");
    setShow(false);
  };
  const handleShow = (data) => {
    setUserId(data._id);
    setFullName(data.name);
    setEmail(data.email);
    // setPassword(data.password);
    setPhone(data.mobile);
    setShow(true);
  };

  const checkErrorMessages = () => {
    if (
      fullNameErrorMessage === "" &&
      emailErrorMessage === "" &&
      // passwordErrorMessage === "" &&
      phoneErrorMessage === ""
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checkErrorMessages()) {
      swal("Worrning!", "Pleace Enter Right Data", "error");
    } else {
      const formData = {
        id: userId,
        name: fullName,
        mobile: phone,
        email: email,
        role: "student",
      };
      dispatch(updateUserApi(formData)).then((res) => {
        if (res.payload.message === " User updated Successfully") {
          Swal.fire("Update Data!", res.payload.message, "success");
          handleClose();
        } else {
          Swal.fire("Error!", `${res.payload.message}`, "info");
        }
        dispatch(getAll("student")).then((resulet) => {
          if (resulet.payload.message === "fetched successfully") {
            setUsers(resulet.payload.data);
          }
        });
      });
    }
  };

  const { loading, error } = useSelector((state) => state.getUser);

  const [users, setUsers] = useState([]);
  const [backupData,setBackupData] = useState([]);

  const [data, setData] = useState(
    document.querySelectorAll("#student_wrapper tbody tr")
  );
  const sort = 3;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#student_wrapper tbody tr"));
    //chackboxFun();
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  // hanele click
  const handleClick = (action, data) => {
    if (action === "DELETE") {
      Swal.fire({
        title: "Are you sure?",
        text: "You are going to delete this Student",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteUser(data._id)).then((res) => {
            if (res.payload.message === "deleted successfully") {
              Swal.fire("Deleted!", "User has been deleted", "success");
            } else {
              Swal.fire(
                "Error!",
                "Something bad happened ,try again later!!",
                "info"
              );
            }
            dispatch(getAll("student")).then((resulet) => {
              if (resulet.payload.message === "fetched successfully") {
                setUsers(resulet.payload.data);
              }
            });
          });
        }
      });
    } else {
      handleShow(data);
    }
  };

  const studentFilters = (e) => {

    if(e.target.value === ""){
      setUsers(backupData)
      return;
    }

    const filterArray = backupData.filter((item) => {
      return (
        item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setUsers(filterArray);
    console.log(filterArray);
  };

  useEffect(() => {
    dispatch(getAll("student")).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        setUsers(resulet.payload.data);
        setBackupData(resulet.payload.data)
      }
    });
    // dispatch(GetUsersSlicer('teacher')).
  }, []);

  return (
    <>
      <div className="row">
        {loading ? (
          <>
            <div className="row d-flex justify-content-center gap-2">
              <Spinner animation="grow" variant="warning" />
              <Spinner animation="grow" variant="warning" />
              <Spinner animation="grow" variant="warning" />
              <Spinner animation="grow" variant="warning" />
            </div>
          </>
        ) : 
        (
          <>
            <div className="col-xl-12">
              <div className="card students-list">
                <div className="card-header border-0 flex-wrap ">
                  <h4>Students List ({users.length})</h4>
                  <div className="input-group search-area w-auto">
                    <span className="input-group-text">
                      <Link to={"#"}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                            fill="var(--primary)"
                          ></path>
                        </svg>
                      </Link>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      onChange={studentFilters}
                    />
                  </div>
                </div>
              </div>
            </div>

            {users.length == 0 ? (
              <>
                <div className="row d-flex text-center gap-2">
                  <h3>There Is No Data</h3>
                </div>
              </>
            ) : (
              <>
                <div className="col-xl-12 ">
                  <div className="card p-1">
                    <div className="card-body ">
                      <div className="table-responsive">
                        <div
                          id="student_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table
                            className="table display mb-4 dataTablesCard order-table card-table text-black application "
                            id="application-tbl1_next"
                          >
                            <thead>
                              <tr>
                                <th>##</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>mobile</th>
                                <th colSpan={2}>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {users &&
                                users.map((item, index) => {
                                  return (
                                    <>
                                      <tr key={item._id}>
                                        <td>#</td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <h4 className="mb-0 fs-16 font-w500">
                                              {item.name}
                                            </h4>
                                          </div>
                                        </td>
                                        <td>{item.email}</td>
                                        <td>{item.mobile}</td>
                                        <td>
                                          <Link
                                            to={`/student-profile/${item._id}`}
                                          >
                                            <i className="fa-solid fa-user" />
                                          </Link>
                                        </td>
                                        <td>
                                          <CustomDropDownBlog
                                            handleClick={handleClick}
                                            id={item._id}
                                            dataitem={item}
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}

                              {/* {studentTableBlog.map((item, index)=>(
												<tr key={index}>
													<td>
														<div className="d-flex align-items-center">
															<img src={item.image} alt="" />
															<h4 className="mb-0 fs-16 font-w500">{item.title}</h4>
															
														</div>
													</td>
													<td>1234567890</td>
													<td>UI Design Courses</td>
													<td>January 2, 2020</td>
													 <td><span className= {`badge  light ${item.changeClass}`}>{item.status}</span></td> 
													<td>
														<DropDownBlog />
													</td>
												</tr>
											))}												 */}
                            </tbody>
                          </table>
                          {/* <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                        <div className="dataTables_info">
                          Showing {activePag.current * sort + 1} to{" "}
                          {data.length > (activePag.current + 1) * sort
                            ? (activePag.current + 1) * sort
                            : data.length}{" "}
                          of {data.length} entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers mb-0"
                          id="application-tbl1_paginate"
                        >
                          <Link
                            className="paginate_button previous "
                            to="/instructor-students"
                            onClick={() =>
                              activePag.current > 0 &&
                              onClick(activePag.current - 1)
                            }
                          >
                            <i
                              className="fa fa-angle-double-left"
                              aria-hidden="true"
                            ></i>
                          </Link>
                          <span>
                            {paggination.map((number, i) => (
                              <Link
                                key={i}
                                to="/instructor-students"
                                className={`paginate_button  ${
                                  activePag.current === i ? "current" : ""
                                } `}
                                onClick={() => onClick(i)}
                              >
                                {number}
                              </Link>
                            ))}
                          </span>

                          <Link
                            className="paginate_button next"
                            to="/instructor-students"
                            onClick={() =>
                              activePag.current + 1 < paggination.length &&
                              onClick(activePag.current + 1)
                            }
                          >
                            <i
                              className="fa fa-angle-double-right"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </div>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Edit User Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-valide" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-12">
                <div className="form-group mb-3 row">
                  <label
                    className="col-lg-4 col-form-label"
                    htmlFor="val-username"
                  >
                    Full Name
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-8">
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="val-username"
                      name="val-username"
                      placeholder="Enter a full name.."
                      onChange={(e) => {
                        if (
                          e.target.value.length < 3 ||
                          e.target.value.length > 50
                        ) {
                          setFullNameErrorMessage(
                            "Min 3 Characters Max 50 Characters"
                          );
                          setFullName(e.target.value);
                        } else {
                          setFullNameErrorMessage("");
                          setFullName(e.target.value);
                        }
                      }}
                      value={fullName}
                    />
                    {fullNameErrorMessage && (
                      <Alert
                        variant="warning"
                        className="alert-dismissible fade mt-1 mb-0"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="me-2"
                        >
                          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                          <line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                        <strong>Warrning </strong>
                        {fullNameErrorMessage}
                      </Alert>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label
                    className="col-lg-4 col-form-label"
                    htmlFor="val-email"
                  >
                    Email <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-8">
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="val-email"
                      name="val-email"
                      placeholder="Your valid email.."
                      onChange={(e) => {
                        if (e.target.value.length < 10) {
                          setEmailErrorMessage(
                            "invaild email min 10 characters"
                          );
                          setEmail(e.target.value);
                        } else {
                          setEmailErrorMessage("");
                          setEmail(e.target.value);
                        }
                      }}
                      value={email}
                    />
                    {emailErrorMessage && (
                      <Alert
                        variant="warning"
                        className="alert-dismissible fade mt-1 mb-0"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="me-2"
                        >
                          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                          <line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                        <strong>Warrning </strong>
                        {emailErrorMessage}
                      </Alert>
                    )}
                  </div>
                </div>

                {/* <div className="form-group mb-3 row">
                  <label
                    className="col-lg-4 col-form-label"
                    htmlFor="val-password"
                  >
                    Password
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group transparent-append mb-2">
                      <span className="input-group-text">
                        <i className="fa fa-lock" />
                      </span>

                      <input
                        required
                        type={`${showPassword ? "text" : "password"}`}
                        className="form-control"
                        id="val-password"
                        name="password"
                        placeholder="Choose a safe one.."
                        onChange={(e) => {
                          if (e.target.value.length < 8) {
                            setPasswordErrorMessage(
                              "Password less then 8 characters"
                            );
                            setPassword(e.target.value);
                          } else {
                            setPasswordErrorMessage("");
                            setPassword(e.target.value);
                          }
                        }}
                        value={password}
                      />

                      <div
                        className="input-group-text "
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {" "}
                        {showPassword === false ? (
                          <i className="fa fa-eye-slash" />
                        ) : (
                          <i className="fa fa-eye" />
                        )}
                      </div>
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                    {passwordErrorMessage && (
                      <Alert
                        variant="warning"
                        className="alert-dismissible fade mt-1 mb-0"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="me-2"
                        >
                          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                          <line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                        <strong>Warrning </strong>
                        {passwordErrorMessage}
                      </Alert>
                    )}
                  </div>
                </div> */}

                <div className="form-group mb-3 row">
                  <label
                    className="col-lg-4 col-form-label"
                    htmlFor="val-phoneus"
                  >
                    Phone (US)
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-8">
                    <input
                      maxLength={11}
                      type="text"
                      className="form-control"
                      id="val-phoneus"
                      name="val-phoneus"
                      placeholder="212-999-0000"
                      onChange={(e) => {
                        if (e.target.value[0] !== "0") {
                          setPhoneErrorMessage("Should Start With 0");
                        } else if (e.target.value[1] !== "1") {
                          setPhoneErrorMessage("Should Start With 01");
                        } else if (e.target.value.length < 11) {
                          setPhoneErrorMessage(
                            "Not Valid Egyption Number 01xxxxxxxxx"
                          );
                        } else {
                          setPhoneErrorMessage("");
                        }
                        setPhone(e.target.value);
                      }}
                      value={phone}
                    />
                    {phoneErrorMessage && (
                      <Alert
                        variant="warning"
                        className="alert-dismissible fade mt-1 mb-0"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="me-2"
                        >
                          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                          <line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                        <strong>Warrning </strong>
                        {phoneErrorMessage}
                      </Alert>
                    )}
                  </div>
                </div>

                <div className="form-group mb-3 row  ">
                  <Col className="d-flex flex-row gap-3 justify-content-end">
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    {userUpdateLoading ? (
                      <>
                        <Button variant="primary" type="submit" disabled>
                          Loading......
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button variant="primary" type="submit">
                          Save Changes
                        </Button>
                      </>
                    )}
                  </Col>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default InstructorStudents;
