import { Modal } from "react-bootstrap";
import { ControlBar, PlaybackRateMenuButton, Player } from "video-react";

export default function DisplayReelVideo({show , onHide , videoUrl}) {
    return (
        <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <h3>play Video</h3>
        </Modal.Header>
        <Modal.Body>
          {videoUrl ? (
            <>
              {/* <ReactPlayer
                className="m-auto"
                url={videoUrl}
                controls
                config={{
                  file: { attributes: { controlsList: "nodownload" } },
                }}
              /> */}
              <Player
                  autoPlay 
                  // src={testVid} 
                  // src="https://api.spacecavez.com/videos/660b569d7c8d5c73b7c748d3" 
                  // https://api.spacecavez.com/dashboard/videos/660b569d7c8d5c73b7c748d3
                  // src="https://api.spacecavez.com/dashboard/videos/660b569d7c8d5c73b7c748d3"
                  // src={`${process.env.REACT_APP_VERSEL_API}/${videoUrl}`}
                  src={videoUrl}
                  //   src={`${process.env.REACT_APP_VERSEL_API}/videos/${lesssonId}/${userId}/${token}`}
                  onTimeUpdate= {(e) => {
                  const timeWhichPlayed = e.target.currentTime;
                  //   const lessonDuration = e.target.duration;
                  //   const halfTime = lessonDuration / 2;
                  const tolerance = 1;
                  //   if(timeWhichPlayed >= halfTime - tolerance && timeWhichPlayed <= halfTime + tolerance) {
                  //     axios.post(postCompletingLessonApi , {} , {withCredentials : true});
                  //   }
                  }}
              >
                  {/* <ControlBar autoHide={false} className="my-class" /> */}
                  <ControlBar>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                  </ControlBar>
              </Player>
            </>
          ) : (
            <>
              <h2>No Video Url</h2>
            </>
          )}
        </Modal.Body>
      </Modal>
    )
}