import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deleteReelFunc } from "../../../store/Slices/reels/DeleteReelSlicer";
import UploadReelVideo from "./UploadReelVideo";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import UpdateReelData from "./UpdateReelData";
import Swal from "sweetalert2";
import DisplayReelVideo from "./DisplayReelVideo";
import UploadReelPoster from "./UploadReelPoster";
export default function AccordionItemReel({
    data,
    refetchReels,
    poster
  }) {
    const [showUploadVideo , setShowUploadVideo] = useState();
    const [showUpdateReelData, setShowUpdateReelData] = useState();
    const [showVideo, setShowVideo] = useState();
    const [videoUrl, setVideoUrl] = useState();
    // const [posterViewr , setPosterViewr] = useState(posetr);
    const [showUploadPosterForm , setShowUploadPosterForm] = useState(false);
    const dispatch = useDispatch();
    const tokenData =  JSON.parse(localStorage.getItem("userData")).token;
    const handleShowVideo = () => {
        setVideoUrl(`${process.env.REACT_APP_VERSEL_API}/dashboard/reel/${data?._id}`);
        setShowVideo(true);
    };
    const handleCloseVideo = () => {
      setVideoUrl(null);
      setShowVideo(false);
    };
    const handleShowUploadPosterForm = () => {
        setShowUploadPosterForm(true);
    };
    const handleDeleteReel = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You are going to delete this Reel",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Delete it!!",
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(deleteReelFunc(data?._id))
                .then((result) => {
                  if (result.payload.message === "Video reel deleted successfully") {
                    Swal.fire("Deleted!", "Reel Deleted Successfuly", "success");
                    refetchReels()
                  } else {
                    Swal.fire(
                      "Error!",
                      "Something bad happened, try again later!!",
                      "info"
                    );
                  }
                });
            }
          });
    }
    return (
      <>
        <div className="acc-courses my-3">
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex align-items-start">
              <span
                className="acc-icon"
                onClick={() => handleShowVideo()}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 13C3.817 13 3.635 12.95 3.474 12.851C3.32918 12.7611 3.20965 12.6358 3.12671 12.4869C3.04378 12.338 3.00016 12.1704 3 12V4C3 3.653 3.18 3.331 3.474 3.149C3.61914 3.05976 3.7846 3.00891 3.95481 3.00121C4.12502 2.99351 4.29439 3.02923 4.447 3.105L12.447 7.105C12.6131 7.1882 12.7528 7.31599 12.8504 7.47405C12.948 7.63212 12.9997 7.81423 12.9997 8C12.9997 8.18578 12.948 8.36789 12.8504 8.52595C12.7528 8.68402 12.6131 8.8118 12.447 8.895L4.447 12.895C4.307 12.965 4.152 13 4 13Z"
                    fill="var(--primary)"
                  />
                </svg>
              </span>
              <h4 className="m-0">
                {data.title && data.title.ar && data.title.en}
                {/* <br /> */}
                {/* {data.title && data.title.en && data.title.ar} */}
                {/* {data.name.en && data.name.en} */}
                <br />
                {data.description?.en && (
                  <>
                    <span className="ml-3" style={{marginRight : "3px"}}>
                      {data.description?.en}
                    </span>
                    {
                      data.free
                      &&
                      <span className="badge bg-success">
                        Free
                      </span>
                    }
                    {
                      data.visible
                      ?
                      <span className="badge bg-success mx-2">
                        Visible
                      </span>
                      :
                      <span className="badge bg-danger mx-2">
                        Not Visible
                      </span>

                    }
                  </>
                )}
              </h4>
            </div>
            <span>
              <Dropdown>
                <Dropdown.Toggle
                  as="a"
                  className="btn-link i-false btn sharp tp-btn-light btn-dark"
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
                      fill="#A098AE"
                    />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="dropdown-menu dropdown-menu-end"
                  align="right"
                >
                  <Dropdown.Item onClick={() => setShowUpdateReelData(true)}>
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setShowUploadVideo(true)}>
                    Upload Video
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleShowUploadPosterForm()}>
                    Upload Poster
                  </Dropdown.Item>
  
                  <Dropdown.Item onClick={() => handleDeleteReel()}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </div>
          {data.file && (
            <>
              <div>
                <h5>
                  <a
                    className="p-1 text-danger"
                    href={`${process.env.REACT_APP_VERSEL_API}/${data.file.path}`}
                    target="_blank"
                  >
                    file name :{data.file.name}
                  </a>
                </h5>
              </div>
            </>
          )}
        </div>
        <UploadReelVideo show={showUploadVideo} onHide={() => setShowUploadVideo(false)} reelId={data?._id} refetchData={() => refetchReels()}/>
        <UploadReelPoster show={showUploadPosterForm} onHide={() => setShowUploadPosterForm(false)} reelId={data?._id} oldPoster={data.image} refetchData={() => refetchReels()}/>
        <UpdateReelData reel={data} showUpdateReelData={showUpdateReelData} onHide={() => setShowUpdateReelData(false)} refetchData={() => refetchReels()}/>
        <DisplayReelVideo videoUrl={videoUrl} show={showVideo} onHide={() => handleCloseVideo()}/>
      </>
    );
  };