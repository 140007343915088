import React, { useEffect, useRef, useState } from "react";

import {
  Tab,
  Nav,
  Accordion,
  Spinner,
  Dropdown,
  Alert,
  Carousel,
} from "react-bootstrap";
import "react-modal-video/css/modal-video.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as Yup from "yup";

import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { FetchCoursesByID } from "../../../store/SingleCourseSlicer";
import swal from "sweetalert";
import { getLessonsApi } from "../../../store/GetAllLessonSlicer";
import { deleteLessonsApi } from "../../../store/DeleteLessonSlicer";
import Swal from "sweetalert2";

import ReactPlayer from "react-player";
import { updateCourseApi } from "../../../store/UpdateCourseDataSlicer";
import axios from "axios";
import { deleteLessonFileApi } from "../../../store/Slices/lessons/DeleteLessonFileSlicer";
import { uploadDemoVideoApi } from "../../../store/Slices/courses/UploadDemoVideoSlicer";
import { getAllQuizesApi } from "../../../store/Slices/quizes/getAllQuizesSlicer";
import { addQuizeToCourse } from "../../../store/Slices/quizes/addQuizeToCourseSlicer";
import { getCourseQuizeApi } from "../../../store/Slices/quizes/getQuizeByCourseIdSlicer";
import { deleteQuizeFormCourseApi } from "../../../store/Slices/quizes/deleteQuizeToCourseSlicer";
import { uploadImageApi } from "../../../store/Slices/courses/UpdateImageCourseSlicer";
// import { toast } from "react-toastify";
import { ControlBar, PlaybackRateMenuButton, Player } from "video-react";
import 'video-react/dist/video-react.css';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import NewReelForm from "../reels/NewReelForm";
import { getCourseReelsFunc } from "../../../store/Slices/reels/GetCourseReelsSlicer";
import AccordionItemReel from "../reels/AccordinItemReel";
import SendCertificateComponents from "../SendCertificates/components/SendCertificateComponents";

const updateCoureSchema = Yup.object().shape({
  enname: Yup.string()
    .min(6, "course name must consist of at least 6 characters ")
    .max(200, "course name must consist of at most 200 characters ")
    .required("Please enter a course name"),
  arname: Yup.string()
    .min(6, "course name must consist of at least 6 characters ")
    .max(200, "course name must consist of at most 200 characters ")
    .required("Please enter a course name"),
  // endescription: Yup.string()
  //   .min(10, "description  must consist of at least 10 characters ")
  //   .max(3000, "description must consist of at most 3000 characters ")
  //   .required("Please enter a course description"),
  // ardescription: Yup.string()
  //   .min(10, "description  must consist of at least 10 characters ")
  //   .max(3000, "description must consist of at most 3000 characters ")
  //   .required("Please enter a course description"),
  // price: Yup.number().required("Please enter a price"),
  // discount: Yup.number().required("Please enter a discount"),
  access_duration: Yup.number().required("Please enter a price"),
});

const lessonsSchemaValidation = Yup.object().shape({
  enname: Yup.string()
    .min(6, "Your english name must consist of at least 6 characters ")
    .max(100, "Your english name must consist of at most 100 characters ")
    .required("Please enter a lesson english name"),
  arname: Yup.string()
    .min(6, "Your arabic name must consist of at least 6 characters ")
    .max(100, "Your arabic name must consist of at most 100 characters ")
    .required("Please enter a lesson arabic name"),

  section: Yup.number().required("Please Select Section"),
  duration: Yup.number().required("Please Duration"),
});

const fileUploadSchema = Yup.object().shape({
  file: Yup.mixed().required("Please upload a file pdf or word"),
});

const AccordionItemLesson = ({
  data,
  deleteLessonFromApi,
  displayLessonVideo,
  updateLessonFrom,
  uploadFileForm,
  deleteLessonFile,
}) => {
  return (
    <>
      <div className="acc-courses my-3">
        <div className="d-flex justify-content-between align-items-start">
          <div className="d-flex align-items-start">
            <span
              className="acc-icon"
              onClick={() => displayLessonVideo(data.video)}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13C3.817 13 3.635 12.95 3.474 12.851C3.32918 12.7611 3.20965 12.6358 3.12671 12.4869C3.04378 12.338 3.00016 12.1704 3 12V4C3 3.653 3.18 3.331 3.474 3.149C3.61914 3.05976 3.7846 3.00891 3.95481 3.00121C4.12502 2.99351 4.29439 3.02923 4.447 3.105L12.447 7.105C12.6131 7.1882 12.7528 7.31599 12.8504 7.47405C12.948 7.63212 12.9997 7.81423 12.9997 8C12.9997 8.18578 12.948 8.36789 12.8504 8.52595C12.7528 8.68402 12.6131 8.8118 12.447 8.895L4.447 12.895C4.307 12.965 4.152 13 4 13Z"
                  fill="var(--primary)"
                />
              </svg>
            </span>
            <h4 className="m-0">
              {data.name && data.name.ar && data.name.en}
              <br />
              {data.name && data.name.en && data.name.ar}
              {/* {data.name.en && data.name.en} */}
              <br />
              {data.duration && (
                <>
                  <span className="ml-3 mr-1 badge bg-success" style={{marginRight : "3px"}}>
                    {data.duration} min
                  </span>
                  {
                    data.is_free
                    &&
                    <span className="badge bg-success">
                      Free
                    </span>
                  }
                </>
              )}
            </h4>
          </div>
          <span>
            <Dropdown>
              <Dropdown.Toggle
                as="a"
                className="btn-link i-false btn sharp tp-btn-light btn-dark"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
                    fill="#A098AE"
                  />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu dropdown-menu-end"
                align="right"
              >
                {data.file ? (
                  <>
                    {data.file.path !== "" && (
                      <>
                        <Dropdown.Item
                          onClick={() => deleteLessonFile(data._id)}
                        >
                          Remove File
                        </Dropdown.Item>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Dropdown.Item onClick={() => uploadFileForm(data._id)}>
                      upload file
                    </Dropdown.Item>
                  </>
                )}
                <Dropdown.Item onClick={() => updateLessonFrom(data, data._id)}>
                  Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => deleteLessonFromApi(data._id)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
        {data.file && (
          <>
            <div>
              <h5>
                <a
                  className="p-1 text-danger"
                  href={`${process.env.REACT_APP_VERSEL_API}/${data.file.path}`}
                  target="_blank"
                >
                  file name :{data.file.name}
                </a>
              </h5>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const AccordionItemQuize = ({ data, deletedQuizeFromCourse }) => {
  return (
    <>
      <div className="acc-courses my-3">
        <div className="d-flex justify-content-between align-items-start">
          <div className="d-flex align-items-start">
            <span className="acc-icon">
              <svg
                fill="#000000"
                width="16px"
                height="16px"
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16.001-7.163 16.001-16s-7.163-16-16.001-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14.001 6.28 14.001 14-6.281 14.032-14.001 14.032zM14.53 25.015h2.516v-2.539h-2.516zM15.97 6.985c-1.465 0-2.672 0.395-3.62 1.184s-1.409 2.37-1.386 3.68l0.037 0.073h2.295c0-0.781 0.261-1.904 0.781-2.308s1.152-0.604 1.893-0.604c0.854 0 1.511 0.232 1.971 0.696s0.689 1.127 0.689 1.989c0 0.725-0.17 1.343-0.512 1.855-0.343 0.512-0.916 1.245-1.721 2.198-0.831 0.749-1.344 1.351-1.538 1.806s-0.297 1.274-0.305 2.454h2.405c0-0.74 0.047-1.285 0.14-1.636s0.36-0.744 0.799-1.184c0.945-0.911 1.703-1.802 2.277-2.674 0.573-0.87 0.86-1.831 0.86-2.881 0-1.465-0.443-2.607-1.331-3.424s-2.134-1.226-3.736-1.226z"></path>
              </svg>
            </span>
            <h4 className="m-0">
              {data.name && data.name}

              <br />
              {data.duration && (
                <>
                  <span className="m-1 mx-3 badge bg-success">
                    {data.duration} min
                  </span>
                </>
              )}
              <br />
              {data.number_of_attempts && (
                <>
                  <span className="m-1 mx-3 badge bg-danger">
                    number of attemps {data.number_of_attempts}
                  </span>
                </>
              )}
            </h4>
          </div>
          <span>
            <Dropdown>
              <Dropdown.Toggle
                as="a"
                className="btn-link i-false btn sharp tp-btn-light btn-dark"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
                    fill="#A098AE"
                  />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu dropdown-menu-end"
                align="right"
              >
                <Dropdown.Item onClick={() => deletedQuizeFromCourse(data._id)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
      </div>
    </>
  );
};

const CustomDropdown = ({
  handleShowCourseEditModule,
  handleShowupdateImagesModle,
  handleShowSendCertificateComponents,
}) => {
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          as="a"
          className="btn-link i-false btn sharp tp-btn-light btn-dark"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
              fill="#A098AE"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-end"
          align="right"
        >
          <Dropdown.Item
            onClick={() => {
              handleShowCourseEditModule();
            }}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handleShowupdateImagesModle();
            }}
          >
            Update Images
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handleShowSendCertificateComponents();
            }}
          >
            Send Certificate
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

// const ListLessons = ({ eventKey, sectionDisplay, AllLessonArray ,uploadFileForm ,deleteLessonFile,deleteLessonFromApi,displayLessonVideo ,handleShowUpdateLessonMedule}) => {
//   const [lessonSection, setLessonSection] = useState();

//   const setAllLessons = ()=>{
//     const LessonData = AllLessonArray.filter(
//       (item) => item.section == sectionDisplay
//     );
//     setLessonSection(LessonData);
//   }

//   useEffect(() => {
//     setAllLessons()
//   },[]);

//   return (
//     <>
//       {lessonSection && lessonSection.length != 0 && (
//         <>
//           <Accordion.Item className="card" eventKey={eventKey}>
//             <Accordion.Header as="h2" className="accordion-header border-0">
//               <span className="acc-heading">Section {eventKey + 1}</span>
//             </Accordion.Header>
//             <Accordion.Collapse eventKey={eventKey}>
//               <div className="accordion-body card-body pt-0">
//                 {lessonSection.map(
//                   (lesson, index) =>
//                       <AccordionItemLesson
//                         key={`${lesson._id}-${index}`}
//                         data={lesson}
//                         uploadFileForm={uploadFileForm}
//                         deleteLessonFile={deleteLessonFile}
//                         deleteLessonFromApi={deleteLessonFromApi}
//                         displayLessonVideo={() =>
//                           displayLessonVideo(lesson._id)
//                         }
//                         updateLessonFrom={handleShowUpdateLessonMedule}
//                       />

//                 )}
//               </div>
//             </Accordion.Collapse>
//           </Accordion.Item>
//         </>
//       )}
//     </>
//   );
// };

const CourseDetail2 = () => {
  /// Update Lesson Area

  const { _id } = useParams();
  const [courseData, setCourseData] = useState([]);

  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.singleCourseData);
  const {
    loading: courseQuizeLoading,
    data: courseQuizeData,
    error: courseQuizeError,
  } = useSelector((state) => state.getQuizeByCourseIdSlicer);
  const { loading: allLessonsLoading, respons } = useSelector(
    (state) => state.getLessons
  );
  const { loading: updatecourseLoading } = useSelector(
    (state) => state.updateCourse
  );
  const { loading: uploadDemo } = useSelector(
    (state) => state.UploadDemoVideoSlicer
  );

  //#region  Add Quize
  const [sectionData, setSectionData] = useState([]);
  const [courseQuizes, setCourseQuize] = useState([]);
  // Add Quiz Model
  const [showQuizForm, setShowQuizForm] = useState(false);
  const [allQuizes, setAllQuizes] = useState([]);
  const [quizeData, setQuizeData] = useState();
  const [discountType, setDiscountType] = useState("fixed");

  const {
    loading: QuizesLoading,
    quizes: QuizesData,
    error: QuizesError,
  } = useSelector((state) => state.getAllQuizesSlicer);

  const handleQuizeSelectedSubmite = (e) => {
    e.preventDefault();
    console.log(quizeData);
    const data = {
      quizeId: quizeData,
      courseId: _id,
    };
    dispatch(addQuizeToCourse(data)).then((res) => {
      if (res.payload.message === "Quize Add To Course Successfully") {
        Swal.fire("Add!", res.payload.message, "success");
        console.log(res.payload.quize);
        setQuizeData();
        getCoures();
        getAllLessonsFromAPI();
        getQuizes();
      }
    });
  };
  const deletedQuizeFromCourse = (quizeId) => {
    dispatch(deleteQuizeFormCourseApi(quizeId)).then((res) => {
      if (res.payload.message === "Quize Removed From Course Successfully") {
        Swal.fire("Deleted!", res.payload.message, "success");
        getCoures();
        getAllLessonsFromAPI();
        getQuizes();
      }
    });
  };

  const getAllQuizes = async () => {
    dispatch(getAllQuizesApi());
  };
  useEffect(() => {
    getAllQuizes();
  }, []);
  useEffect(() => {
    QuizesData && setAllQuizes(QuizesData);
  }, [QuizesData]);

  //#endregion

  //#region Update images

  const [showImageUploadModle, setShowImageUploadModle] = useState(false);

  const [intialValueImageUpload, setIntialValueImageUpload] = useState({
    courseId: _id,
    image_Type: "",
    imagefile: "",
    imageName: "",
  });

  const fileUploaderImgCourse = useRef(null);

  const openFileUploaderImgCourse = () => {
    fileUploaderImgCourse.current.click();
  };

  const handleShowupdateImagesModle = () => {
    setIntialValueImageUpload({
      courseId: _id,
      image_Type: "",
      imagefile: "",
      imageName: "",
    });
    setShowImageUploadModle(true);
  };

  const handleUploadImageCourse = (e) => {
    e.preventDefault();

    dispatch(uploadImageApi(intialValueImageUpload)).then((res) => {
      console.log(res);
      if (res.payload.message === "Data updated successfuly") {
        Swal.fire("Updated!", "Course Data Has Been Updated", "success");
        setIntialValueImageUpload({
          courseId: _id,
          image_Type: "",
          imagefile: "",
          imageName: "",
        });
        setShowImageUploadModle(false);
        getCoures();
      } else {
        Swal.fire("Error!", "Some Error Happen", "warning");
      }
    });

    console.log(intialValueImageUpload);
  };

  //#endregion

  //#region update course details

  const [courseFormIntilization, setCourseFormInitialization] = useState({
    enname: "",
    arname: "",
    endescription: "",
    ardescription: "",
    freeStatus : false,
    price: 0,
    // discount_price: 0,
    discount_type: "",
    teacherId: "",
    courseId: "",
    access_duration: 0,
  });

  // handle Update Data

  // Show Update Model
  const [courseEditModule, setCourseEditModule] = useState(false);
  const handleCloseCourseEditModule = () => {
    setCourseEditModule(false);
    setCourseFormInitialization({
      enname: "",
      arname: "",
      endescription: "",
      ardescription: "",
      price: 0,
      // discount_price : 0,
      discount_type : "",
      teacherId: "",
      courseId: "",
      access_duration: 0,
    });
  };
  const [freeStatus , setFreeStatus] = useState();
  const [visipilatyStatus , setvisipilatyStatus] = useState();
  const [discountPrice , setDiscountPrice] = useState();
  const handleShowCourseEditModule = (id) => {
    setCourseFormInitialization({
      enname: courseData.name.en,
      arname: courseData.name.ar,
      endescription: courseData.description.en,
      ardescription: courseData.description.ar,
      // discount_price: courseData.discount_price,
      discount_type: courseData.discount_type,
      // freeStatus : courseData.is_free,
      price: courseData.price / 100,
      teacherId: courseData.teacher_id._id,
      courseId: _id,
      access_duration: courseData.access_duration
        ? courseData.access_duration
        : 12,
    });
    setCourseEditModule(true);
  };

  useEffect(() => {
    // courseData && setFreeStatus(courseData.is_free)
    courseData && setvisipilatyStatus(courseData.is_visible)
    // courseData && setDiscountPrice(courseData.discount_price)
  },[courseData])
  const handleUpdateCourseData = (value, { resetForm }) => {
    console.log(value);
    let formData = new FormData();
    formData.append("name[en]", value.enname);
    formData.append("name[ar]", value.arname);
    // formData.append("description[en]", value.endescription);
    formData.append("description[en]", courseEnglishDescription);
    // formData.append("description[ar]", value.ardescription);
    formData.append("description[ar]", courseArabicDescription);
    // formData.append("is_free", freeStatus);
    formData.append("is_visible", visipilatyStatus);
    // freeStatus ? formData.append("price", 0) : formData.append("price", value.price * 100);
    // (freeStatus || !discountPrice || parseInt(discountPrice) < 1) ? formData.append("discount_price", 0) : formData.append("discount_price", discountPrice)
    // (freeStatus || !discountType || discountType === "") ? formData.append("discount_type", "fixed") : formData.append("discount_type", discountType)
    // formData.append("discount_type", discountType)
    formData.append("teacherId", value.teacherId);
    formData.append("courseId", value.courseId);
    formData.append("access_duration", value.access_duration);
    const sendDataFunc = () => {
      dispatch(updateCourseApi(formData)).then((res) => {
        if (res.payload.message == "Done") {
          Swal.fire("Updated!", "Course Data Has Been Updated", "success");
          handleCloseCourseEditModule();
          resetForm();
          getCoures();
        } else {
          Swal.fire("Error!", res.payload.message, "warning");
        }
      });
    }
    sendDataFunc();
    // if(freeStatus) {
    // }else {
    //   if(parseInt(value.price) < 10) {
    //     Swal.fire({
    //       title: "Oops!",
    //       text: "Min Price 10$!",
    //       icon: "warning"
    //     });
    //   }else {
    //     sendDataFunc();
    //   }
    // }
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  };

  const [demoUrl, setDemoUrl] = useState("");
  const [demoUrlErrorMessage, setDemoUrlErrorMessage] = useState("");

  const handleUploadDemoUrl = () => {
    if (demoUrlErrorMessage === "") {
      const courseData = {
        courseId: _id,
        demo_video: demoUrl,
      };
      dispatch(uploadDemoVideoApi(courseData)).then((res) => {
        console.log(res);
        if (res.payload.status == 200) {
          Swal.fire("Updated!", "Course Data Has Been Updated", "success");
          setDemoUrlErrorMessage("");
          setDemoUrl("");
          getCoures();
        } else {
          Swal.fire("Error!", res.payload.message, "error");
        }
      });
    }
  };

  //#endregion

  //#region Add Lesson
  /*----------------------------- */
  // create Lesson Area
  // Add Lesson Model

  const createLessonFileRefreance = useRef(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [createLesson, setCreateLesson] = useState({});
  const [showLessonForm, setShowLessonForm] = useState(false);
  const [lessonFreeStatus, setLessonFreeStatus] = useState(false);
  const [newLessonFreeStatus, setNewLessonFreeStatus] = useState();
  const [courseEnglishDescription , setCourseEnglishDescription] = useState("");
  const [courseArabicDescription , setCourseArabicDescription] = useState("");

  const handleCloseLessonForm = () => {
    setShowLessonForm(false);
    setCreateLesson({
      enname: "",
      arname: "",
      section: 0,
      duration: 1,
      video: "",
      videsName: "",
    });
  };
  const handleShowLessonForm = () => {
    setShowLessonForm(true);
    setCreateLesson({
      enname: "",
      arname: "",
      section: 0,
      duration: 1,
      video: "",
      videsName: "",
    });
  };
  const openCreateLessonFile = () => {
    createLessonFileRefreance.current.click();
  };

  //Form handler
  const handleLessonFormUlpoader = async (value, { resetForm }) => {
    setIsUploading(true);
    setProgress(0);

    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    let formData = new FormData();
    formData.append("courseId", _id);
    formData.append("name[en]", value.enname);
    formData.append("name[ar]", value.arname);
    formData.append("section", value.section);
    formData.append("is_free", lessonFreeStatus);
    formData.append("duration", value.duration);
    value.video && formData.append("video", value.video);

    const request = await axios
      .request({
        timeout: 0,
        method: "post",
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        url: `${process.env.REACT_APP_VERSEL_API}/api/users/lessons/create`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenData}`,
        },
        data: formData,
        onUploadProgress: (e) => {
          const uploadedRateData = Math.floor(
            Math.round(100 * e.loaded) / e.total
          );
          setProgress(uploadedRateData);
          console.log(uploadedRateData);
        },
      })
      .then((result) => {
        setIsUploading(false);
        setProgress(0);

        if (result.data.message === "lesson added successfully") {
          swal("Lesson", "Lesson Add Successfuly ", "success");
          resetForm();
          getAllLessonsFromAPI();
          handleCloseLessonForm();
        } else {
          swal("Error", result.data.message, "error");
          setIsUploading(false);
          setProgress(0);
        }
      })
      .catch((error) => {
        console.log(error);
        swal("Oops", `${error}`, "error");
      });
  };

  // Update Lesson Area

  const [progressUpdate, setProgressUpdate] = useState(0);
  const [isUploadingUpdate, setIsUploadingUpdate] = useState(false);
  const [showUpdateLesson, setShowUpdateLesson] = useState(false);

  const [formIntilaData, setFormIntilaData] = useState({
    lessonId: "",
    enname: "",
    arname: "",
    section: 0,
    duration: 1,
    video: "",
    videsName: "",
  });

  const fileUploaderLessonVideo = useRef(null);

  const openFileUploaderLessonVideos = () => {
    fileUploaderLessonVideo.current.click();
  };

  const handleShowUpdateLessonMedule = (data, lessonId) => {
    setFormIntilaData({
      lessonId: lessonId,
      enname: data.name.en,
      arname: data.name.ar,
      // freeStatus: data.is_free,
      duration: data.duration,
      section: data.section,
    });
    setNewLessonFreeStatus(data.is_free)
    setShowUpdateLesson(true);
  };

  const handleCloseUpdateLessonMedule = () => {
    setFormIntilaData({
      lessonId: "",
      enname: "",
      arname: "",
      section: 0,
      duration: 1,
      video: "",
      videsName: "",
    });
    setShowUpdateLesson(false);
  };

  const handleFormSubmite = async (value, { resetForm }) => {
    setIsUploadingUpdate(true);
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    let projectFormData = new FormData();
    projectFormData.append("name[en]", value.enname);
    projectFormData.append("name[ar]", value.arname);
    projectFormData.append("section", value.section);
    projectFormData.append("is_free", newLessonFreeStatus);
    projectFormData.append("duration", value.duration);

    if (value.video !== "") {
      projectFormData.append("video", value.video);
    }
    await axios
      .request({
        timeout: 0,
        method: "put",
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        url: `${process.env.REACT_APP_VERSEL_API}/api/users/lessons/update/${value.lessonId}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenData}`,
        },
        data: projectFormData,

        onUploadProgress: (e) => {
          const uploadedRateData = Math.floor(
            Math.round(100 * e.loaded) / e.total
          );
          setProgressUpdate(uploadedRateData);
        },
      })
      .then((result) => {
        setIsUploadingUpdate(false);
        if (result.data.message === "Done") {
          swal("Done!", result.data.message, "success");
          resetForm();
          setProgressUpdate(0);
          setIsUploadingUpdate(false);
          getAllLessonsFromAPI();
          setShowUpdateLesson(false);
        } else {
          swal("Error", result.data.message, "error");
          setProgressUpdate(0);
          setIsUploadingUpdate(false);
        }
      })
      .catch((error) => {
        swal("Oops", `${error}`, "error");
        setProgressUpdate(0);
        setIsUploadingUpdate(false);
      });
  };

  // Show Videos Model
  const [videoUrl, setVideoUrl] = useState(null);
  const [showVideoLesson, setShowVideoLesson] = useState(false);
  const [showNewReelForm, setShowNewReelForm] = useState(false);

  // handle cloase module
  const handleCloseVideoLesson = () => {
    setVideoUrl(null);
    setShowVideoLesson(false);
  };
  // handle get All Lessons
  const getAllLessonsFromAPI = () => {
    dispatch(getLessonsApi(_id)).then((resulet) => {
      setSectionData(resulet.payload.data);
    });
  };

  // handle Delete Lesson
  const deleteLessonFromApi = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete this lesson",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteLessonsApi(id)).then((res) => {
          if (res.payload.message === "Lesson Deleted successfully") {
            Swal.fire("Deleted!", res.payload.message, "success");
            getAllLessonsFromAPI();
          } else {
            Swal.fire("Deleted!", res.payload.message, "error");
          }
        });
      }
    });
  };

  // handle Show Lesson
  const displayLessonVideo = (lessonId) => {
    setVideoUrl(
      `${process.env.REACT_APP_VERSEL_API}/dashboard/videos/${lessonId}`
    );
    console.log(lessonId)
    setShowVideoLesson(true);
  };

  //#endregion

  //#region upload file area

  /*----------------------------- */
  /// upload file area

  const [fileLessonIsUploading, setFileLessonIsUploading] = useState(false);
  const [fileLessonProgressBar, setFileLessonProgressBar] = useState(0);

  const updateNewfileUploaderLessonVideo = useRef(null);
  const openUpdateFileUploaderLessonVideos = () => {
    updateNewfileUploaderLessonVideo.current.click();
  };

  const fileLessonUploaderRefreance = useRef(null);
  const openFileLessonUploader = () => {
    fileLessonUploaderRefreance.current.click();
  };

  const [fileUploaderInitialData, setFileUploaderInitialData] = useState({
    lessonId: "",
    type: "",
    filename: "",
    file: "",
  });
  const [showFileLessonUploadModule, setShowFileLessonUploadModule] =
    useState(false);

  const handleCloceFileLessonUploadModule = () => {
    setShowFileLessonUploadModule(false);
    setFileLessonIsUploading(false);
    setFileLessonProgressBar(0);
    setFileUploaderInitialData({
      lessonId: "",
      filename: "",
      file: "",
    });
  };

  const uploadFileForm = (lessonId) => {
    console.log(lessonId);
    setFileUploaderInitialData({
      lessonId: lessonId,
      filename: "",
      file: "",
    });
    setShowFileLessonUploadModule(true);
  };

  const handelSubmitFileUploaderForm = async (value, { resetForm }) => {
    console.log(value);
    setFileLessonIsUploading(true);
    setFileLessonProgressBar(0);

    const token = JSON.parse(localStorage.getItem("userData")).token;
    const formData = new FormData();
    formData.append("file", value.file);
    await axios
      .request({
        method: "POST",
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        url: `${process.env.REACT_APP_VERSEL_API}/api/lessons/file/uploadeFile/${value.lessonId}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        data: formData,
        onUploadProgress: (e) => {
          const uploadedRatedData = Math.floor(
            Math.round(100 * e.loaded) / e.total
          );
          setFileLessonProgressBar(uploadedRatedData);
        },
      })
      .then((res) => {
        if (res.data.message === "File added successfully") {
          setFileLessonIsUploading(false);
          swal("File", "File Add Successfuly ", "success");
          resetForm();
          getAllLessonsFromAPI();
          handleCloceFileLessonUploadModule();
        } else if (res.data.message === "File updated successfully") {
          swal("File", "File updated Successfuly ", "success");
          resetForm();
          getAllLessonsFromAPI();
          handleCloceFileLessonUploadModule();
        } else {
          swal("File", res.data.message, "info");
        }
      })
      .catch((err) => {
        setFileLessonIsUploading(false);
        setFileLessonProgressBar(0);
        swal("Oops", `${err}`, "error");
      });
  };

  const deleteLessonFile = (lesId) => {
    dispatch(deleteLessonFileApi(lesId)).then((res) => {
      if (res.payload.message === "File deleted successfully") {
        swal("File", "File Deleted Successfuly ", "success");
        getAllLessonsFromAPI();
      } else {
        swal("File", res.payload.message, "error");
      }
    });
  };

  //#endregion

  //#region Display Lessosn Accordin Component
  const LessonDiplayAccordin = ({ sectionNumber }) => {
    const [section, setSection] = useState();
    const fillSection = async () => {
      const filterSection = sectionData.filter(
        (item) => item.section == sectionNumber
      );
      setSection(filterSection);
    };

    useEffect(() => {
      fillSection();
    }, []);

    return (
      <>
        {section && section.length !== 0 && (
          <>
            <Accordion.Item
              className="card"
              eventKey={sectionNumber}
              key={sectionNumber + "lllskdlah"}
            >
              <Accordion.Header as="h2" className="accordion-header border-0">
                <span className="acc-heading">Section {sectionNumber + 1}</span>
              </Accordion.Header>
              <Accordion.Collapse eventKey={sectionNumber}>
                <div className="accordion-body card-body pt-0">
                  {section.map((lesson, index) => (
                    <AccordionItemLesson
                      key={`${lesson._id}-${index}`}
                      data={lesson}
                      uploadFileForm={uploadFileForm}
                      deleteLessonFile={deleteLessonFile}
                      deleteLessonFromApi={deleteLessonFromApi}
                      displayLessonVideo={() => displayLessonVideo(lesson._id)}
                      updateLessonFrom={handleShowUpdateLessonMedule}
                    />
                  ))}
                  <>
                  {/* <Accordion.Item
                                      className="card"
                                      eventKey={mainindex}
                                      key={mainindex + "lllskdlah"}
                                    >
                                      <Accordion.Header
                                        as="h2"
                                        className="accordion-header border-0"
                                      >
                                        <span className="acc-heading">
                                          Section {mainindex + 1}
                                        </span>
                                      </Accordion.Header>
                                      <Accordion.Collapse eventKey={mainindex}>
                                        <div className="accordion-body card-body pt-0">
                                          {sectionData.map(
                                            (lesson, index) =>
                                              lesson.section == mainindex && (
                                                <AccordionItemLesson
                                                  key={`${lesson._id}-${index}`}
                                                  data={lesson}
                                                  uploadFileForm={
                                                    uploadFileForm
                                                  }
                                                  deleteLessonFile={
                                                    deleteLessonFile
                                                  }
                                                  deleteLessonFromApi={
                                                    deleteLessonFromApi
                                                  }
                                                  displayLessonVideo={() =>
                                                    displayLessonVideo(
                                                      lesson._id
                                                    )
                                                  }
                                                  updateLessonFrom={
                                                    handleShowUpdateLessonMedule
                                                  }
                                                />
                                              )
                                          )}
                                        </div>
                                      </Accordion.Collapse>
                                    </Accordion.Item> */}
                  </>
                </div>
              </Accordion.Collapse>
            </Accordion.Item>
          </>
        )}
      </>
    );
  };
  //#endregion
  const [showSendCertificateModal , setShowSendCertificateModal] = useState(false);
  const [watchedList , setWatchedList] = useState([]);
  const [backupData,setBackupData] = useState([]);
  const getCoures = async () => {
    dispatch(FetchCoursesByID(_id)).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        console.log(resulet.payload.data);
        setCourseData(resulet.payload.data.course);
        setWatchedList(resulet.payload.data.userWatchData);
        setBackupData(resulet.payload.data.userWatchData);
        setCourseEnglishDescription(resulet.payload.data.course.description.en);
        setCourseArabicDescription(resulet.payload.data.course.description.ar);
        resulet.payload.data.course.discount_type 
        ?
        setDiscountType(resulet.payload.data.course.discount_type) 
        :
        setDiscountType('fixed') 
      }
    });
  };
  const getQuizes = async () => {
    dispatch(getCourseQuizeApi(_id)).then((resulet) => {
      if (resulet.payload.message === "Quize fetched Successfully") {
        setCourseQuize(resulet.payload.quize);
        console.log(resulet.payload.quize);
      }
    });
  };
  useEffect(() => {
    getCoures();
    getAllLessonsFromAPI();
    getQuizes();
  }, []);

  useEffect(() => {
    if (!courseData) {
      getCoures();
    }
    return;
  }, [courseData]);

  useEffect(() => {
    if (!courseQuizes) {
      getQuizes();
    }
    return;
  }, [courseQuizes]);

  // const checkFreeStatusFunc = () => {
  //   if(freeStatus) {
  //     toast.success("This Course Will be Free");
  //   }else {
  //     toast.error("This Course Will be Not Free");
  //   }
  // }
  // useEffect(() => {
  //   checkFreeStatusFunc()
  // },[freeStatus])

    const colors = [
    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
    '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
    '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
    '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
    '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
    '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
  ];
  const modules = {
      toolbar: {
      container: [
      [{"color" : colors}],
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['clean']
      ],
  }
  };
  const {loading:getReelsLoading} = useSelector(
    state => state.getCourseReelsSlicer
  )
  const [reelsList , setReelsList] = useState([]);
  const getCourseReels = () => {
    console.log("get reels")
    try {
      dispatch(getCourseReelsFunc(_id))
      .then((result) => {
        setReelsList(result.payload)
      })
    }catch(error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getCourseReels()
  },[])

  const studentFilters = (e) => {

    if(e.target.value === ""){
      setWatchedList(backupData)
      return;
    }

    const filterArray = backupData.filter((item) => {
      return (
        item.user.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.user.email.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setWatchedList(filterArray);
    console.log(filterArray);
  };
  return (
    <>
      {loading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>{error}</>
          ) : (
            <>
              {courseData && (
                <>
                  <div className="row">
                    <div className="col-xl-6 col-xxl-6">
                      <div className="row">
                        <div className="card">
                          <div className="card-body">
                            <div className="course-content ">
                              <div className="mb-2">
                                <CustomDropdown
                                  handleShowCourseEditModule={
                                    handleShowCourseEditModule
                                  }
                                  handleShowupdateImagesModle={
                                    handleShowupdateImagesModle
                                  }
                                  handleShowSendCertificateComponents={
                                    () => setShowSendCertificateModal(true)
                                  }
                                />
                              </div>
                              <div>
                                <h3>{courseData.name && courseData.name.en}</h3>
                                <ul className="d-flex align-items-center raiting my-0 flex-wrap">
                                  {courseData.teacher_id != null && (
                                    <li>
                                      Instructor : {courseData.teacher_id.name}
                                    </li>
                                  )}
                                  {/* {
                                    courseData.discount_price && parseInt(courseData.discount_price) > 0
                                    ?
                                    <li className="text-danger">OFF {courseData.discount_type === "fixed" ? parseInt(courseData.discount_price)/100 : parseInt(courseData.discount_price)} {courseData.discount_type === "fixed" ? "EG" : "%"}</li>
                                    :
                                    ""
                                  } */}
                                  {/* {
                                    courseData.is_free
                                    ?
                                    <li>Free</li>
                                    :
                                    (
                                      courseData.discount_price && parseInt(courseData.discount_price) > 0
                                      ?
                                      <li>Price : <del className="text-danger">{courseData.price / 100}$</del> <span>{courseData.final_price / 100}$</span></li>
                                      :
                                      <li>Price : {courseData.price / 100}$</li>
                                    )
                                  } */}
                                  <li>Duration : {courseData.duration}</li>
                                </ul>
                                <p>
                                  Access Duration:{" "}
                                  {courseData.access_duration &&
                                    courseData.access_duration}{" "}
                                  Month
                                </p>
                              </div>
                            </div>

                            <Tab.Container defaultActiveKey="About">
                              <div className="course-details-tab style-2 mt-4">
                                <nav>
                                  <Nav
                                    as="div"
                                    className="nav nav-tabs tab-auto"
                                    id="nav-tab"
                                  >
                                    <Nav.Link
                                      as="button"
                                      className="nav-link"
                                      id="nav-about-tab"
                                      eventKey="About"
                                      type="button"
                                    >
                                      English Data
                                    </Nav.Link>
                                    <Nav.Link
                                      as="button"
                                      className="nav-link"
                                      id="nav-aboutar-tab"
                                      eventKey="Aboutar"
                                      type="button"
                                    >
                                      Arabic Data
                                    </Nav.Link>
                                  </Nav>
                                </nav>
                                <Tab.Content
                                  className="tab-content"
                                  id="nav-tabContent"
                                  style={{
                                    textAlign: "justify",
                                  }}
                                >
                                  <Tab.Pane id="nav-about" eventKey="About">
                                    <div className="about-content">
                                      <h4>Course Name</h4>
                                      <p>
                                        {courseData.name && courseData.name.en}
                                      </p>
                                      <h4>About This Course</h4>
                                      <p>
                                        {
                                          courseData.description 
                                          &&
                                          <div dangerouslySetInnerHTML={{__html : courseData.description.en}}/>
                                        }
                                      </p>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane
                                    id="nav-about"
                                    eventKey="Aboutar"
                                    dir="rtl"
                                  >
                                    <div className="about-content">
                                      <h4>اسم الكورس</h4>
                                      <p>
                                        {courseData.name && courseData.name.ar}
                                      </p>
                                      <h4>وصف الكورس</h4>
                                      <p>
                                        {
                                          courseData.description 
                                          &&
                                          <div dangerouslySetInnerHTML={{__html : courseData.description.ar}}/>
                                        }
                                      </p>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                            </Tab.Container>
                            {/* <Carousel>
                              <Carousel.Item key={1}>
                                <img
                                  src={`${process.env.REACT_APP_VERSEL_API}/${courseData.image}`}
                                  className="d-block w-100"
                                  alt={`Slide 1`}
                                />
                              </Carousel.Item>
                              <Carousel.Item key={2}>
                                <img
                                  src={`${process.env.REACT_APP_VERSEL_API}/${courseData.wallpaper}`}
                                  className="d-block w-100"
                                  alt={`Slide 2`}
                                />
                              </Carousel.Item>
                            </Carousel> */}
                            {/* <div className="video-img style-1 my-3">
                           <div className="view-demo">
                              <img
                                  src={`${process.env.REACT_APP_VERSEL_API}/${courseData.wallpaper}`}
                                  className="d-block w-100"
                                  alt={`Slide 2`}
                                />
                            </div>
                           </div> */}
                            {/* <div className="video-img style-1 my-3">
                            <div className="view-demo">
                              <img
                                  src={`${process.env.REACT_APP_VERSEL_API}/${courseData.image}`}
                                  className="d-block w-100"
                                  alt={`Slide 1`}
                                />
                            </div>
                            
                          </div>  */}
                            <div className="my-3">
                              <img
                                src={`${process.env.REACT_APP_VERSEL_API}/${courseData.wallpaper}`}
                                className="d-block w-100"
                                alt={`Slide 2`}
                              />
                            </div>
                            <div className="my-3">
                              <img
                                src={`${process.env.REACT_APP_VERSEL_API}/${courseData.image}`}
                                className="d-block w-100"
                                alt={`Slide 1`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-xxl-6">
                      <div className="card h-auto">
                        <div className="card-header border-0 pb-0">
                          <h4>Demo Video</h4>
                        </div>

                        <div className="card-body pt-0 d-flex gap-1 row">
                          <div className="col-sm-12">
                            <input
                              type="url"
                              className="form-control"
                              placeholder="youtube link"
                              onChange={(e) => {
                                setDemoUrlErrorMessage("");
                                if (!isValidUrl(e.target.value)) {
                                  setDemoUrlErrorMessage("invalid Link");
                                }

                                setDemoUrl(e.target.value);
                              }}
                              value={demoUrl}
                            />

                            {uploadDemo ? (
                              <>
                                <button className="btn btn-primary mt-2">
                                  <Spinner animation="grow" variant="warning" />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-primary mt-2"
                                  onClick={handleUploadDemoUrl}
                                >
                                  Upload
                                </button>
                              </>
                            )}

                            {courseData.demo_video && (
                              <>
                                <a
                                  className="btn btn-info mt-2 mx-3"
                                  href={courseData.demo_video}
                                  target="_blank"
                                >
                                  watch
                                </a>
                              </>
                            )}
                          </div>
                          <div className="col-12">
                            {demoUrlErrorMessage && (
                              <>
                                <Alert variant="warning">
                                  {demoUrlErrorMessage}
                                </Alert>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card h-auto">
                        <div className="card-header border-0 pb-0">
                          <h4>ِAdded Lesson ,Quize</h4>
                        </div>
                        <div className="card-body pt-0 d-flex gap-1">
                          <button
                            className="btn btn-primary"
                            onClick={handleShowLessonForm}
                          >
                            New Lesson
                          </button>

                          {courseQuizes.length === 0 && (
                            <>
                              <button
                                className="btn btn-danger"
                                onClick={() => setShowQuizForm(true)}
                              >
                                Add Quize
                              </button>
                            </>
                          )}
                              <button
                                className="btn btn-danger"
                                onClick={() => setShowNewReelForm(true)}
                              >
                                Add Reel
                              </button>
                        </div>
                      </div>
                      <div className="custome-accordion">
                        <Accordion
                          className="accordion"
                          defaultActiveKey={[`0`]}
                          alwaysOpen
                        >
                          {courseQuizeLoading ? (
                            <>
                              <div className="d-flex justify-content-center align-items-center gap-1 w-100">
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                              </div>
                            </>
                          ) : (
                            <>
                              {courseQuizes.length == 0 ? (
                                <h3 className="text-center">
                                  No Quize Added yet
                                </h3>
                              ) : (
                                <>
                                  {courseQuizes.map((quize, index) => (
                                    <Accordion.Item
                                      className="card"
                                      eventKey={100}
                                      key={index}
                                    >
                                      <Accordion.Header
                                        as="h2"
                                        className="accordion-header border-0"
                                      >
                                        <span className="acc-heading">
                                          Quize
                                        </span>
                                      </Accordion.Header>
                                      <Accordion.Collapse eventKey={100}>
                                        <div className="accordion-body card-body pt-0">
                                          <AccordionItemQuize
                                            data={quize}
                                            deletedQuizeFromCourse={
                                              deletedQuizeFromCourse
                                            }
                                          />
                                        </div>
                                      </Accordion.Collapse>
                                    </Accordion.Item>
                                  ))}
                                </>
                              )}
                            </>
                          )}
                        </Accordion>
                        <Accordion
                          className="accordion"
                          defaultActiveKey={[`0`]}
                          alwaysOpen
                        >
                          {allLessonsLoading ? (
                            <>
                              <div className="d-flex justify-content-center align-items-center gap-1 w-100">
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                              </div>
                            </>
                          ) : (
                            <>
                              {sectionData.length == 0 ? (
                                <h3 className="text-center">
                                  No lessons Added yet
                                </h3>
                              ) : (
                                <>
                                  {[
                                    0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,
                                  ].map((mainitem, mainindex) => (
                                    <LessonDiplayAccordin key={mainindex} sectionNumber={mainindex}  />
                                  ))}
                                </>
                              )}
                            </>
                          )}
                        </Accordion>

                        <Accordion
                          className="accordion"
                          defaultActiveKey={[`0`]}
                          // alwaysOpen
                        >
                          {getReelsLoading ? (
                            <>
                              <div className="d-flex justify-content-center align-items-center gap-1 w-100">
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                              </div>
                            </>
                          ) : (
                            <>
                              {reelsList.length == 0 ? (
                                <h3 className="text-center">
                                  No Reels Added yet
                                </h3>
                              ) : (
                                <>
                                  {/* {reelsList.map((reel, index) => ( */}
                                              <Accordion.Item
                                                className="card"
                                                eventKey={"0"}
                                                // key={index + "lllskdlah"}
                                              >
                                                <Accordion.Header as="h2" className="accordion-header border-0">
                                                  <span className="acc-heading">Reels</span>
                                                </Accordion.Header>
                                                <Accordion.Collapse eventKey={"0"}>
                                                  <div className="accordion-body card-body pt-0">
                                                    {reelsList.map((reel, index) => (
                                                      <AccordionItemReel
                                                        key={`${reel._id}-${index}`}
                                                        data={reel}
                                                        refetchReels = {getCourseReels}
                                                        posetr={reel.image}
                                                      />
                                                    ))}
                                                    <>
                                                    </>
                                                  </div>
                                                </Accordion.Collapse>
                                              </Accordion.Item>
                                  {/* ))} */}
                                </>
                              )}
                            </>
                          )}
                        </Accordion>
                      </div>
                    </div>
                      <>
                        <div className="col-xl-12">
                          <div className="card students-list">
                            <div className="card-header border-0 flex-wrap ">
                              <h4>Views History List ({watchedList.length})</h4>
                              <div className="input-group search-area w-auto">
                                <span className="input-group-text">
                                  <Link to={"#"}>
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                        fill="var(--primary)"
                                      ></path>
                                    </svg>
                                  </Link>
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here..."
                                  onChange={studentFilters}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {watchedList.length == 0 ? (
                          <>
                            <div className="row d-flex text-center gap-2">
                              <h3>There Is No Data</h3>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-xl-12 ">
                              <div className="card p-1">
                                <div className="card-body ">
                                  <div className="table-responsive">
                                    <div
                                      id="student_wrapper"
                                      className="dataTables_wrapper no-footer"
                                    >
                                      <table
                                        className="table display mb-4 dataTablesCard order-table card-table text-black application "
                                        id="application-tbl1_next"
                                      >
                                        <thead>
                                          <tr>
                                            <th>##</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Total Minutes</th>
                                            <th>Profile</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {watchedList &&
                                            watchedList.map((item, index) => {
                                              const {user , totalMinutesWatched} = item;
                                              return (
                                                <>
                                                  <tr key={user._id}>
                                                    <td>#</td>
                                                    <td>
                                                      <div className="d-flex align-items-center">
                                                        <h4 className="mb-0 fs-16 font-w500">
                                                          {user.name}
                                                        </h4>
                                                      </div>
                                                    </td>
                                                    <td>{user.email}</td>
                                                    <td>{totalMinutesWatched?.toFixed(2)} M</td>
                                                    <td>
                                                      <Link
                                                        to={`/student-profile/${item._id}`}
                                                      >
                                                        <i className="fa-solid fa-user" />
                                                      </Link>
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                  </div>
                </>
              )}
            </>
          )}

          {/* Add Quize Selector */}
          <Modal
            show={showQuizForm}
            onHide={() => setShowQuizForm(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <h3>Add Quize To This Course</h3>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleQuizeSelectedSubmite}>
                <div className="row">
                  <div className={` col-lg-12 col-sm-12 form-group mb-3`}>
                    <label className="text-label">Select Qize</label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        name="question_type"
                        onChange={(e) => {
                          setQuizeData(e.target.value);
                        }}
                        value={quizeData}
                      >
                        <option defaultValue={null}>Please select</option>
                        {QuizesData &&
                          allQuizes &&
                          allQuizes.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowQuizForm(false)}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => setShowQuizForm(false)}
                    type="submit"
                  >
                    Add Quize
                  </Button>
                  {/* {updatecourseLoading ? (
                        <>
                          <Button variant="primary" disabled>
                            Updateting ...
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button variant="primary" type="submit">
                            Update Course
                          </Button>
                        </>
                      )} */}
                </Modal.Footer>
              </form>
            </Modal.Body>
          </Modal>
          {/* Update Images */}
          <Modal
            show={showImageUploadModle}
            onHide={() => {
              setShowImageUploadModle(false);
            }}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <h3>Update Images</h3>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleUploadImageCourse}>
                <div className="row">
                  <div className={` col-lg-12 col-sm-12 form-group mb-3`}>
                    <label className="text-label">Select image</label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        name="question_type"
                        onChange={(e) => {
                          setIntialValueImageUpload({
                            ...intialValueImageUpload,
                            image_Type: e.target.value,
                          });
                        }}
                        value={intialValueImageUpload.image_Type}
                      >
                        <option defaultValue={null}>Please select</option>
                        <option value={"wallpaper"}>
                          home image 250 X 400
                        </option>
                        <option value={"image"}>
                          the main image 700 X 450
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={`col-lg-12 col-sm-12 form-group mb-3`}>
                    <label
                      className="form-label"
                      onClick={openFileUploaderImgCourse}
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        padding: "2rem",
                        backgroundColor: "#1f88d9",
                        opacity: "0.5",
                        border: "3px dashed blue",
                        borderRadius: "10px",
                        color: "#111",
                        fontSize: "1rem",
                        textAlign: "center",
                        overflow: "hidden",
                      }}
                    >
                      {intialValueImageUpload.imageName
                        ? intialValueImageUpload.imageName
                        : "Upload New Image Course 700 X 450 typr of jpg , png , jpeg , webp"}
                    </label>
                    <div className="input-group">
                      <input
                        ref={fileUploaderImgCourse}
                        hidden
                        className="form-control form-control-md"
                        type="file"
                        onChange={(e) => {
                          setIntialValueImageUpload({
                            ...intialValueImageUpload,
                            imagefile: e.target.files[0],
                            imageName: e.target.files[0].name,
                          });
                          // setValues({
                          //   ...values,
                          //   imageName: e.target.files[0].name,
                          //   image: e.target.files[0],
                          // });
                        }}
                      />
                      {/* <div
                                id="val-file-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.file && errors.file}
                              </div> */}
                    </div>
                  </div>
                </div>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowImageUploadModle(false)}
                  >
                    Close
                  </Button>
                  {intialValueImageUpload.image_Type !== "" &&
                    intialValueImageUpload.imagefile !== "" && (
                      <>
                        <Button variant="primary" type="submit">
                          Upload Image
                        </Button>
                      </>
                    )}

                  {/* {updatecourseLoading ? (
                        <>
                          <Button variant="primary" disabled>
                            Updateting ...
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button variant="primary" type="submit">
                            Update Course
                          </Button>
                        </>
                      )} */}
                </Modal.Footer>
              </form>
            </Modal.Body>
          </Modal>

          {/* Display Videso Lesson */}
          <Modal
            show={showVideoLesson}
            onHide={handleCloseVideoLesson}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <h3>play Video</h3>
            </Modal.Header>
            <Modal.Body>
              {videoUrl ? (
                <>
                  {/* <ReactPlayer
                    className="m-auto"
                    url={videoUrl}
                    controls
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                  /> */}
                  <Player
                      autoPlay 
                      // src={testVid} 
                      // src="https://api.spacecavez.com/videos/660b569d7c8d5c73b7c748d3" 
                      // https://api.spacecavez.com/dashboard/videos/660b569d7c8d5c73b7c748d3
                      // src="https://api.spacecavez.com/dashboard/videos/660b569d7c8d5c73b7c748d3"
                      // src={`${process.env.REACT_APP_VERSEL_API}/${videoUrl}`}
                      src={videoUrl}
                      //   src={`${process.env.REACT_APP_VERSEL_API}/videos/${lesssonId}/${userId}/${token}`}
                      onTimeUpdate= {(e) => {
                      const timeWhichPlayed = e.target.currentTime;
                      //   const lessonDuration = e.target.duration;
                      //   const halfTime = lessonDuration / 2;
                      const tolerance = 1;
                      //   if(timeWhichPlayed >= halfTime - tolerance && timeWhichPlayed <= halfTime + tolerance) {
                      //     axios.post(postCompletingLessonApi , {} , {withCredentials : true});
                      //   }
                      }}
                  >
                      {/* <ControlBar autoHide={false} className="my-class" /> */}
                      <ControlBar>
                      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                      </ControlBar>
                  </Player>
                </>
              ) : (
                <>
                  <h2>No Video Url</h2>
                </>
              )}
            </Modal.Body>
          </Modal>

          {/* Update Course Data Formik  */}
          <Modal
            show={courseEditModule}
            onHide={handleCloseCourseEditModule}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Body>
              <Formik
                initialValues={{
                  enname: courseFormIntilization.enname,
                  arname: courseFormIntilization.arname,
                  // endescription: courseFormIntilization.endescription,
                  // ardescription: courseFormIntilization.ardescription,
                  // freeStatus : courseFormIntilization.freeStatus,
                  // price: courseFormIntilization.price,
                  // discount_price: courseFormIntilization.discount_price,
                  teacherId: courseFormIntilization.teacherId,
                  courseId: courseFormIntilization.courseId,
                  access_duration: courseFormIntilization.access_duration,
                }}
                validationSchema={updateCoureSchema}
                onSubmit={handleUpdateCourseData}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div>
                      <h1>Basic Infromation</h1>
                      <section>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.enname
                                ? errors.enname
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English Coures Name
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a english name.."
                                name="enname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.enname}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.enname && errors.enname}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.arname
                                ? errors.arname
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic Course Name
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a arabic name.."
                                name="arname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.arname}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.arname && errors.arname}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="free-status-div row">
                          <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                            <input id="avilable-for-free"  type="checkbox" className="mr-1 cursor-pointer scale2"
                              onChange={(e) => {
                                setFreeStatus(!freeStatus);
                              }}
                              checked = {freeStatus}
                            />
                            <label htmlFor="avilable-for-free" className="is-free-label cursor-pointer pl-2 mb-0">Avilable For Free</label>
                          </div>
                        </div> */}
                        <div className="visiblaty-div row">
                          <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                            <input id="visiblaty"  type="checkbox" className="mr-1 cursor-pointer scale2"
                              onChange={(e) => {
                                setvisipilatyStatus(!visipilatyStatus);
                              }}
                              checked = {visipilatyStatus}
                            />
                            <label htmlFor="visiblaty" className="is-free-label cursor-pointer pl-2 mb-0">Visability</label>
                          </div>
                        </div>
                        <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                  values.access_duration
                                    ? errors.access_duration
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Enter Access Duration
                                </label>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter course access duration"
                                    name="access_duration"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.access_duration}
                                  />
                                  <div
                                    id="val-access_duration-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.access_duration &&
                                      errors.access_duration}
                                  </div>
                                </div>
                              </div>
                         {/*{
                          !freeStatus
                          &&
                          <>
                            <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                  values.price
                                    ? errors.price
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Enter New Price* (In Dollar)
                                </label>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter course price .."
                                    name="price"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.price}
                                  />
                                  <div
                                    id="val-price-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.price && errors.price}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 
                            
                                `}
                              >
                                <label className="text-label">
                                  Choose discount type*
                                </label>
                                <div className="input-group">
                                  <select
                                    className="form-control"
                                    name="discount-type"
                                    onChange={(e) => setDiscountType(e.target.value)}
                                    defaultValue={discountType}
                                  >
                                    <option value={"Choose Discount Type.."} disabled>Choose Discount Type..</option>
                                    <option value={"fixed"}>Fixed</option>
                                    <option value={"percentage"}>Percentage</option>
                                  </select>
                                  <div
                                    id="val-price-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.discountType && errors.discountType}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                  values.discount_price
                                    ? errors.discount_price
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Enter Discount*
                                </label>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter course discount .."
                                    name="discount"
                                    onChange={(e) => setDiscountPrice(e.target.value)}
                                    onBlur={handleBlur}
                                    value={discountPrice}
                                  />
                                  <div
                                    id="val-price-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.discount_price && errors.discount_price}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        } */}
                      </section>
                    </div>
                    <div>
                      <h1>Descriptions </h1>
                      <section>
                        <div className="row ">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.endescription
                                ? errors.endescription
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English Description
                            </label>
                            {/* <div className="input-group">
                              <textarea
                                id="subdescription-textarea"
                                rows={5}
                                className="form-control"
                                placeholder="Sub Description"
                                name="endescription"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endescription}
                              ></textarea>
                              <div
                                id="val-endescription-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endescription && errors.endescription}
                              </div>
                            </div> */}
                            <ReactQuill
                              modules={modules}
                              className="p-0 border-none outline-none form-control bg-white my-3"
                              // value={values.endescription}
                              value={courseEnglishDescription}
                              // name="endescription"
                              // data-name="endescription"
                              theme="snow"
                              // onChange={handleChange}
                              // onChange={(value) => {
                              //   setCourseEnglishDescription(value);
                              //   console.log(<div dangerouslySetInnerHTML={{__html : value}} />)
                              // }}
                              onChange={(value) => {
                                setCourseEnglishDescription(value)
                                // const textWithoutTags = value.replace(/<[^>]+>/g, '');
                                // textWithoutTags.length < 10
                                // ?
                                // setErrEnDescription(true)
                                // :
                                // setErrEnDescription(false)
                              }}
                            />
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.ardescription
                                ? errors.ardescription
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic Description
                            </label>
                            {/* <div className="input-group">
                              <textarea
                                id="subdescription-textarea"
                                rows={5}
                                className="form-control"
                                placeholder="Sub Description"
                                name="ardescription"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ardescription}
                              ></textarea>
                              <div
                                id="val-ardescription-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.ardescription && errors.ardescription}
                              </div>
                            </div> */}
                            <ReactQuill
                              modules={modules}
                              className="p-0 border-none outline-none form-control bg-white my-3"
                              value={courseArabicDescription}
                              theme="snow"
                              onChange={(value) => {
                                setCourseArabicDescription(value)
                                // const textWithoutTags = value.replace(/<[^>]+>/g, '');
                                // textWithoutTags.length < 10
                                // ?
                                // setErrArDescription(true)
                                // :
                                // setErrArDescription(false)
                              }}
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCloseCourseEditModule}
                      >
                        Close
                      </Button>
                      {updatecourseLoading ? (
                        <>
                          <Button variant="primary" disabled>
                            Updateting ...
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button variant="primary" type="submit">
                            Update Course
                          </Button>
                        </>
                      )}
                    </Modal.Footer>
                  </form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>

          {/* Create Lesson Form */}
          <Modal
            show={showLessonForm}
            onHide={handleCloseLessonForm}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header>Add Lesson</Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  enname: createLesson.enname,
                  arname: createLesson.arname,
                  section: createLesson.section,
                  duration: createLesson.duration,
                  video: createLesson.video,
                }}
                validationSchema={lessonsSchemaValidation}
                onSubmit={handleLessonFormUlpoader}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <div className="row">
                        <div
                          className={` col-lg-12 col-sm-12 form-group mb-3 ${
                            values.enname
                              ? errors.enname
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">
                            Enter Lesson English Name
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa-solid fa-file-signature"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter a english name.."
                              name="enname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.enname}
                            />
                            <div
                              id="val-enname-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.enname && errors.enname}
                            </div>
                          </div>
                        </div>
                        <div
                          className={` col-lg-12 col-sm-12 form-group mb-3 ${
                            values.arname
                              ? errors.arname
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">
                            Enter Lesson Arabic Name
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa-solid fa-file-signature"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter a arabic name.."
                              name="arname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.arname}
                            />
                            <div
                              id="val-arname-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.arname && errors.arname}
                            </div>
                          </div>
                        </div>

                        <div
                          className={`col-12 form-group mb-3 ${
                            values.section
                              ? errors.section
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">Select Section </label>
                          <div className="input-group transparent-append mb-2">
                            <select
                              className="form-control"
                              name="section"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.section}
                              placeholder="Chose Project Service"
                            >
                              <option defaultValue={""}>Please select</option>
                              {[
                                0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,
                              ].map((mainitem, mainindex) => (
                                <option value={mainindex}>
                                  section {mainindex + 1}
                                </option>
                              ))}
                            </select>
                            <div
                              id="val-section-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.section && errors.section}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-12 form-group mb-3 ${
                            values.duration
                              ? errors.duration
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">Enter Duration </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa-solid fa-file-signature"></i>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter a video duration.."
                              name="duration"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.duration}
                            />
                            <div
                              id="val-duration-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.duration && errors.duration}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="free-status-div row">
                        <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                          <input id="avilable-lesson-for-free"  type="checkbox" className="mr-1 cursor-pointer scale2"
                            onChange={(e) => {
                              setLessonFreeStatus(!lessonFreeStatus);
                            }}
                            checked = {lessonFreeStatus}
                          />
                          <label htmlFor="avilable-lesson-for-free" className="is-free-label cursor-pointer pl-2 mb-0">Avilable For Free</label>
                        </div>
                      </div>
                      {/* Image Inputs Data */}
                      <div className="row">
                        <div
                          className="card-header"
                          style={{
                            border: "transparent",
                          }}
                        >
                          <h4 className="card-title">Uploade Video</h4>
                        </div>
                        {/* Video  */}
                        <div className={`col-lg-12 col-sm-12 form-group mb-3`}>
                          <label
                            onClick={openCreateLessonFile}
                            className="text-label"
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              padding: "2rem",
                              backgroundColor: "#1f88d9",
                              opacity: "0.5",
                              border: "3px dashed blue",
                              borderRadius: "10px",
                              color: "#111",
                              fontSize: "1rem",
                              textAlign: "center",
                              overflow: "hidden",
                            }}
                          >
                            {values.videsName
                              ? values.videsName
                              : "  Upload New Video  type of mp4 , mov , wmv , m4v"}
                          </label>
                          <div className="input-group">
                            <input
                              accept="video/*"
                              type="file"
                              className="form-control"
                              name="video"
                              hidden
                              ref={createLessonFileRefreance}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  videsName: e.target.files[0].name,
                                  video: e.target.files[0],
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {isUploading && (
                        <>
                          <h5>uploading video now please waite...</h5>
                          <div className="progress-box">
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0 fs-14 font-w600">
                                Uploading video new.., please don't close window
                              </h5>
                              <span className="font-w600" id="progress-span">
                                {progress}%
                              </span>
                            </div>
                            <div className="progress ">
                              <div
                                className="progress-bar bg-primary"
                                id="progress-inner-width"
                                style={{
                                  width: `${progress}%`,
                                  height: "12px",
                                  borderRadius: "4px",
                                }}
                                role="progressbar"
                              ></div>
                            </div>
                          </div>
                        </>
                      )}

                      {isUploading ? (
                        <>
                          <button className="btn me-2 btn-primary" disabled>
                            <Spinner animation="grow" variant="warning" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn me-2 btn-primary"
                          >
                            Submit
                          </button>
                        </>
                      )}
                      <button
                        type="button"
                        onClick={handleCloseLessonForm}
                        className="btn me-2 btn-danger"
                      >
                        Close
                      </button>
                    </>
                  </form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>

          {/* Update Lesson Form */}
          <Modal
            show={showUpdateLesson}
            onHide={handleCloseUpdateLessonMedule}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>Update Lesson</Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  lessonId: formIntilaData.lessonId,
                  enname: formIntilaData.enname,
                  arname: formIntilaData.arname,
                  freeStatus: formIntilaData.freeStatus,
                  section: formIntilaData.section,
                  duration: formIntilaData.duration,
                  video: formIntilaData.video,
                }}
                validationSchema={lessonsSchemaValidation}
                onSubmit={handleFormSubmite}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <div className="row">
                        <div
                          className={` col-lg-12 col-sm-12 form-group mb-3 ${
                            values.enname
                              ? errors.enname
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">
                            Enter Lesson English Name
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa-solid fa-file-signature"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter a english name.."
                              name="enname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.enname}
                            />
                            <div
                              id="val-enname-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.enname && errors.enname}
                            </div>
                          </div>
                        </div>
                        <div
                          className={` col-lg-12 col-sm-12 form-group mb-3 ${
                            values.arname
                              ? errors.arname
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">
                            Enter Lesson Arabic Name
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa-solid fa-file-signature"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter a arabic name.."
                              name="arname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.arname}
                            />
                            <div
                              id="val-arname-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.arname && errors.arname}
                            </div>
                          </div>
                        </div>

                        <div
                          className={`col-12 form-group mb-3 ${
                            values.section
                              ? errors.section
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">Select Section </label>
                          <div className="input-group transparent-append mb-2">
                            <select
                              className="form-control"
                              name="section"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.section}
                              placeholder="Chose Section Number "
                            >
                              <option defaultValue={""}>Please select</option>
                              {[
                                0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,
                              ].map((mainitem, mainindex) => (
                                <option value={mainindex}>
                                  section {mainindex + 1}
                                </option>
                              ))}
                            </select>
                            <div
                              id="val-section-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.section && errors.section}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-12 form-group mb-3 ${
                            values.duration
                              ? errors.duration
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">Enter Duration </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa-solid fa-file-signature"></i>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter a video duration.."
                              name="duration"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.duration}
                            />
                            <div
                              id="val-duration-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.duration && errors.duration}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="free-status-div row">
                        <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                          <input id="update-avilable-lesson-for-free"  type="checkbox" className="mr-1 cursor-pointer scale2"
                            onChange={(e) => {
                              setNewLessonFreeStatus(!newLessonFreeStatus);
                            }}
                            checked = {newLessonFreeStatus}
                          />
                          <label htmlFor="update-avilable-lesson-for-free" className="is-free-label cursor-pointer pl-2 mb-0">Avilable For Free</label>
                        </div>
                      </div>
                      {/* Image Inputs Data */}
                      <div className="row">
                        <div
                          className="card-header"
                          style={{
                            border: "transparent",
                          }}
                        >
                          <h4 className="card-title">Uploade Video</h4>
                        </div>
                        {/* Video  */}
                        <div className={`col-lg-12 col-sm-12 form-group mb-3`}>
                          <label
                            onClick={openUpdateFileUploaderLessonVideos}
                            className="text-label"
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              padding: "2rem",
                              backgroundColor: "#1f88d9",
                              opacity: "0.5",
                              border: "3px dashed blue",
                              borderRadius: "10px",
                              color: "#111",
                              fontSize: "1rem",
                              textAlign: "center",
                              overflow: "hidden",
                            }}
                          >
                            {values.videsName
                              ? values.videsName
                              : "  Upload New Video  type of mp4 , mov , wmv , m4v "}
                          </label>
                          <div className="input-group">
                            <input
                              accept="video/*"
                              type="file"
                              className="form-control"
                              name="video"
                              hidden
                              ref={updateNewfileUploaderLessonVideo}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  videsName: e.target.files[0].name,
                                  video: e.target.files[0],
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {isUploadingUpdate && (
                        <>
                          <h5>uploading video now please waite...</h5>
                          <div className="progress-box">
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0 fs-14 font-w600">
                                Uploading video new.., please don't close window
                              </h5>
                              <span className="font-w600" id="progress-span">
                                {progressUpdate}%
                              </span>
                            </div>
                            <div className="progress ">
                              <div
                                className="progress-bar bg-primary"
                                id="progress-inner-width"
                                style={{
                                  width: `${progressUpdate}%`,
                                  height: "12px",
                                  borderRadius: "4px",
                                }}
                                role="progressbar"
                              ></div>
                            </div>
                          </div>
                        </>
                      )}
                      {isUploadingUpdate ? (
                        <>
                          <button className="btn me-2 btn-primary" disabled>
                            <Spinner animation="grow" variant="warning" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn me-2 btn-primary"
                          >
                            Submit
                          </button>
                        </>
                      )}
                      <button
                        type="button"
                        onClick={handleCloseUpdateLessonMedule}
                        className="btn me-2 btn-danger"
                      >
                        Close
                      </button>
                    </>
                  </form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>

          {/* Upload File Form */}
          <Modal
            show={showFileLessonUploadModule}
            onHide={handleCloceFileLessonUploadModule}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body>
              <Formik
                initialValues={{
                  lessonId: fileUploaderInitialData.lessonId,
                  filename: fileUploaderInitialData.filename,
                  file: fileUploaderInitialData.file,
                }}
                validationSchema={fileUploadSchema}
                onSubmit={handelSubmitFileUploaderForm}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <div className="row">
                        <div
                          className="card-header"
                          style={{
                            border: "transparent",
                          }}
                        >
                          <h4 className="card-title">Upload Lesson File</h4>
                        </div>
                        {/* Video  */}
                        <div
                          className={` col-lg-12 col-sm-12 form-group mb-3 ${
                            values.file
                              ? errors.file
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label
                            onClick={openFileLessonUploader}
                            className="text-label"
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              padding: "2rem",
                              backgroundColor: "#1f88d9",
                              opacity: "0.5",
                              border: "3px dashed blue",
                              borderRadius: "10px",
                              color: "#111",
                              fontSize: "1rem",
                              textAlign: "center",
                              overflow: "hidden",
                            }}
                          >
                            {values.filename
                              ? values.filename
                              : "  Upload New File type of word , pdf "}
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              className="form-control"
                              name="file"
                              hidden
                              ref={fileLessonUploaderRefreance}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  filename: e.target.files[0].name,
                                  file: e.target.files[0],
                                });
                              }}
                            />
                          </div>
                          <div
                            id="val-name-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.file && errors.file}
                          </div>
                        </div>
                      </div>
                      {fileLessonIsUploading && (
                        <>
                          <div className="progress-box">
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0 fs-14 font-w600">
                                Uploading file now.., please don't close window
                              </h5>
                              <span className="font-w600" id="progress-span">
                                {fileLessonProgressBar}%
                              </span>
                            </div>
                            <div className="progress ">
                              <div
                                className="progress-bar bg-primary"
                                id="progress-inner-width"
                                style={{
                                  width: `${fileLessonProgressBar}%`,
                                  height: "12px",
                                  borderRadius: "4px",
                                }}
                                role="progressbar"
                              ></div>
                            </div>
                          </div>
                        </>
                      )}
                      {fileLessonIsUploading ? (
                        <>
                          <button className="btn me-2 btn-primary" disabled>
                            <Spinner animation="grow" variant="warning" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn me-2 btn-primary"
                          >
                            upload
                          </button>
                        </>
                      )}
                      <button
                        type="button"
                        onClick={handleCloceFileLessonUploadModule}
                        className="btn me-2 btn-danger"
                      >
                        Close
                      </button>
                    </>
                  </form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
          <NewReelForm 
          showNewReelForm={showNewReelForm} 
          onHide={() => setShowNewReelForm(false)}
          courseId={_id}
          refetchReel = {getCourseReels}
          />
          <SendCertificateComponents show={showSendCertificateModal} onHide ={() => setShowSendCertificateModal(false)}  //onFinshed ={} 
          courseData={courseData}/>
        </>
      )}
    </>
  );
};
export default CourseDetail2;
