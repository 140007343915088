import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import course6 from "./../../../images/courses/course6.jpg";
import avatar from "../../../images/avatar/avatar-main.png";

export default function CustomCouseCard({ title, subtitle, price, _id,image , freeStatus}) {
    return <>
      <div className="col-xl-4 col-md-6">
        <div className="card all-crs-wid">
          <div className="card-body">
            <div className="courses-bx">
              <div className="dlab-media">
              {image?
                <img src={`${process.env.REACT_APP_VERSEL_API}/${image}`} alt={title} />
              
              :
              <img src={avatar} alt={title} />
              
              }
              </div>
              <div className="dlab-info">
                <div className="dlab-title d-flex justify-content-between">
                  <div>
                    <h4>
                      <Link to={`/course-details-2/${_id}`}>{title}</Link>
                    </h4>
                    <p className="m-0">
                      {subtitle&&subtitle}
                      <svg
                        className="ms-1"
                        width="4"
                        height="5"
                        viewBox="0 0 4 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="2" cy="2.5" r="2" fill="#DBDBDB" />
                      </svg>
                      {/* <span>
                        5.0
                        <svg
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z"
                            fill="#FEC64F"
                          />
                        </svg>
                      </span> */}
                    </p>
                  </div>
                  {/* <h4 className="text-primary">
                    {
                      freeStatus
                      ?
                      "Free Course"
                      :
                      <>
                        <span>$</span>
                        {price/100}
                      </>
                    }
                  </h4> */}
                </div>
                <div className="d-flex justify-content-between content align-items-center">
                
                  <Link
                    to={`/course-details-2/${_id}`}
                    className="btn btn-primary btn-sm"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  
};

